import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../state/axios-instance";

const ProductDetail = () => {
  const [product, setProduct] = useState(null);
  const [relatedProduct, setRelatedProduct] = useState(null);
  const baseImageURL = "https://api.hoangnhatminh.vn";
  const { slug } = useParams();

  useEffect(() => {
    console.log(slug);
    axios
      .get(`/product/${slug}`)
      .then((response) => {
        console.log(response);
        setProduct(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (product) {
      axios
        .get(`/product/relatedproduct/${product.category}`)
        .then((res) => {
          console.log("Related res:", res);
          setRelatedProduct(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [product]);

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <div class="body-wrapper">
        <header class="ltn__header-area ltn__header-3 sticky-top bg-white shadow">
          {/* <!-- ltn__header-top-area end --> 
    <!-- ltn__header-middle-area start --> */}
          <div class="ltn__header-middle-area">
            <div class="container">
              <div class="row">
                <div class="col d-flex">
                  <div class="col site-logo">
                    <a href="/">
                      <img src="assets/img/logo.png" alt="Logo" />
                    </a>
                  </div>
                  <div class="col header-feature-item">
                    <div class="header-feature-icon">
                      <i class="icon-call"></i>
                    </div>
                    <div class="header-feature-info">
                      <h6>Phone</h6>
                      <p>
                        <a href="#">028.6292.5568</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col header-contact-serarch-column d-none d-lg-block">
                  <div class="header-contact-search">
                    <div className="col header-menu-column menu-color-white---">
                      <div className="header-menu d-none d-xl-block">
                        <nav>
                          <div className="ltn__main-menu">
                            <ul>
                              <li>
                                <a href="/">Trang chủ</a>
                              </li>
                              <li>
                                <a href="/shop">Sản phẩm</a>
                              </li>
                              <li>
                                <a href="/aboutUs">Liên hệ</a>
                              </li>
                            </ul>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- header-bottom-area end --> */}
        </header>
        {/* <!-- HEADER AREA END --> */}

        <div class="ltn__utilize-overlay"></div>

        {/* <!-- BREADCRUMB AREA START --> */}
        <div
          class="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image"
          style={{
            // backgroundImage: "url('/assets/img/bg/11.jpg')",
            // width: "100%",
            // position: "relative",
            // left: "0px",
            // top: "0px",
            // zIndex: "999",
            // opacity: "1",
            position: "relative",
            width: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              opacity: "1",
              zIndex: -1,
              backgroundImage: "url('/assets/img/bg/11.jpg')",
              backgroundPosition: "center",
              backgroundSize: "cover",
              filter: "blur(8px)",
            }}
          ></div>
          <div
            class="container"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div class="row">
              <div class="col-lg-12">
                <div
                  class="ltn__breadcrumb-inner"
                  style={{
                    position: "relative",
                    color: "#ffffff",
                    float: "left",
                  }}
                >
                  <h1 class="page-title">SẢN PHẨM</h1>
                  <div class="ltn__breadcrumb-list">
                    <ul
                      style={{
                        color: "black",
                      }}
                    >
                      <li>
                        <a href="#">
                          <span class="ltn__secondary-color">
                            <i class="fas fa-home"></i>
                          </span>{" "}
                          Trang chủ
                        </a>
                      </li>
                      <li>Sản phẩm</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- BREADCRUMB AREA END --> */}

        <div class="ltn__shop-details-area pb-85">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 col-md-12">
                <div class="ltn__shop-details-inner mb-60">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="ltn__shop-details-img-gallery">
                        <div class="ltn__shop-details-large-img">
                          <div class="single-large-img">
                            <a
                              href="img/product/1.png"
                              data-rel="lightcase:myCollection"
                            >
                              <img
                                className="img-fluid"
                                style={{ width: "100%", objectFit: "cover" }}
                                src={`${baseImageURL}/images/${product.imagePath[0]}`}
                                alt={product.name}
                              />{" "}
                            </a>
                          </div>
                        </div>
                        <div class="ltn__shop-details-small-img slick-arrow-2">
                          <div class="single-small-img">
                            {/* <img
                              src={`${baseImageURL}/images/${product.imagePath[1]}`}
                              alt={product.name}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="modal-product-info shop-details-info pl-0">
                        <h3>{product.name}</h3>
                        <div class="product-price">
                          {product.price
                            ? product.price.toLocaleString("it-IT", {
                                style: "currency",
                                currency: "VND",
                              })
                            : "Liên hệ"}
                        </div>
                        <div class="modal-product-meta ltn__product-details-menu-1">
                          <ul>
                            <li>
                              <strong>Categories:</strong>
                              <span>
                                <a href="/shop">{product.category}</a>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="short-desc">{product.description}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ltn__shop-details-tab-inner ltn__shop-details-tab-inner-2">
                  <div class="ltn__shop-details-tab-menu">
                    <div class="nav">
                      <a
                        class="active show"
                        data-bs-toggle="tab"
                        href="#liton_tab_details_1_1"
                      >
                        Description
                      </a>
                      {/* <a
                        data-bs-toggle="tab"
                        href="#liton_tab_details_1_2"
                        class=""
                      >
                        Reviews
                      </a> */}
                    </div>
                  </div>
                  <div class="tab-content">
                    <div
                      class="tab-pane fade active show"
                      id="liton_tab_details_1_1"
                    >
                      <div class="ltn__shop-details-tab-content-inner">
                        <h4 class="title-2">
                          Lorem ipsum dolor sit amet elit.
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum. Sed ut
                          perspiciatis unde omnis iste natus error sit
                          voluptatem, totam rem aperiam, eaque ipsa quae ab illo
                          inventore veritatis et quasi architecto beatae vitae
                          dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                          voluptas sit aspernatur aut odit aut fugit, sed quia
                          consequuntur magni dolores eos qui ratione voluptatem
                          sequi nesciunt. Neque porro quisquam est, qui dolorem
                          ipsum quia dolor sit amet, consectetur, adipisci
                          velit, sed quia non numquam eius modi tempora incidunt
                          ut labore et dolore magnam aliquam quaerat voluptatem.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <aside class="sidebar ltn__shop-sidebar ltn__right-sidebar">
                  <div class="widget ltn__top-rated-product-widget">
                    <h4 class="ltn__widget-title ltn__widget-title-border">
                      Top Rated Product
                    </h4>
                    <ul>
                      {/* NEW */}
                      {relatedProduct
                        ? relatedProduct.map((product) => (
                            <li>
                              <div class="top-rated-product-item clearfix">
                                <div class="top-rated-product-img">
                                  <a href={`/product/${product.slug}`}>
                                    <img
                                      src={`${baseImageURL}/images/${product.imagePath[0]}`}
                                      alt="#"
                                    />
                                  </a>
                                </div>
                                <div class="top-rated-product-info">
                                  <div class="product-ratting">
                                    <ul>
                                      <li>
                                        <a href="#">
                                          <i class="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i class="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i class="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i class="fas fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i class="fas fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <h6>
                                    <a href={`/product/${product.slug}`}>
                                      {product.name}
                                    </a>
                                  </h6>
                                  <div class="product-price">
                                    <span>
                                      {product.price
                                        ? product.price.toLocaleString(
                                            "it-IT",
                                            {
                                              style: "currency",
                                              currency: "VND",
                                            }
                                          )
                                        : "Liên hệ"}
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))
                        : "Loading..."}
                    </ul>
                  </div>
                  <div class="widget ltn__banner-widget">
                    <a href="shop.html">
                      <img src="/assets/img/banner/2.jpg" alt="#" />
                    </a>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- FOOTER AREA START --> */}
        <footer class="ltn__footer-area  ">
          <div class="footer-top-area  section-bg-2 plr--5">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-4 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-about-widget">
                    {/* <div class="footer-logo">
                      <div class="site-logo">
                        <img src="assets/img/logo.png" alt="Logo" />
                      </div>
                    </div> */}
                    <h4 class="footer-title">VỀ HNM</h4>

                    <p>
                      HoangNhatMinh luôn tự hào là nhà tiên phong trong lĩnh vực
                      thuốc với nhiều sáng tạo và sản phẩm đã được công nhận bởi
                      bộ y tế về an toàn và hiệu quả đem đến cho người sử dụng.
                    </p>

                    <div class="ltn__social-media mt-20">
                      <ul>
                        <li>
                          <a href="#" title="Facebook">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Twitter">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Linkedin">
                            <i class="fab fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Youtube">
                            <i class="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-menu-widget clearfix">
                    <h4 class="footer-title">HỖ TRỢ</h4>
                    <div class="footer-menu">
                      <ul>
                        <li>
                          <a>FAQ</a>
                        </li>
                        <li>
                          <a>Chính sách bảo mật</a>
                        </li>
                        <li>
                          <a>Chính sách thanh toán</a>
                        </li>
                        <li>
                          <a>Chính sách giao hàng</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-menu-widget clearfix">
                    <h4 class="footer-title">SHOP</h4>
                    <div class="footer-menu">
                      <ul>
                        <li>
                          <a href="/shop">ELISA</a>
                        </li>
                        <li>
                          <a href="/shop">PCR</a>
                        </li>
                        <li>
                          <a href="/shop">Test thử nhanh</a>
                        </li>
                        <li>
                          <a href="/shop">Làm đẹp</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-newsletter-widget">
                    <h4 class="footer-title">LIÊN HỆ</h4>
                    <div class="footer-address">
                      <ul>
                        <li>
                          <div class="footer-address-icon">
                            <i class="icon-placeholder"></i>
                          </div>
                          <div class="footer-address-info">
                            <p>72 Đường C18, Tân Bình, Tp. Hồ Chí Minh</p>
                          </div>
                        </li>
                        <li>
                          <div class="footer-address-icon">
                            <i class="icon-call"></i>
                          </div>
                          <div class="footer-address-info">
                            <p>
                              <a href="tel:+02862925568">(028).6292.5568</a>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div class="footer-address-icon">
                            <i class="icon-mail"></i>
                          </div>
                          <div class="footer-address-info">
                            <p>
                              <a href="mailto:example@example.com">
                                hoangnhatminh@lab.com
                              </a>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    {/* <img
                      class="mt-30"
                      src="assets/img/logo-bct.png"
                      alt="Payment Image"
                      width={150}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
            <div class="container-fluid ltn__border-top-2">
              <div class="row">
                <div class="col-md-12 col-12 text-center">
                  <div class="ltn__copyright-design clearfix">
                    <p>
                      All Rights Reserved @ Hoang Nhat Minh{" "}
                      <span class="current-year"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* <!-- FOOTER AREA END --> */}

        {/* <!-- MODAL AREA START (Quick View Modal) --> */}
        <div class="ltn__modal-area ltn__quick-view-modal-area">
          <div class="modal fade" id="quick_view_modal" tabindex="-1">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    {/* <!-- <i class="fas fa-times"></i> --> */}
                  </button>
                </div>
                <div class="modal-body">
                  <div class="ltn__quick-view-modal-inner">
                    <div class="modal-product-item">
                      <div class="row">
                        <div class="col-lg-6 col-12">
                          <div class="modal-product-img">{/* $1 /> */}</div>
                        </div>
                        <div class="col-lg-6 col-12">
                          <div class="modal-product-info">
                            <div class="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="far fa-star"></i>
                                  </a>
                                </li>
                                <li class="review-total">
                                  {" "}
                                  <a href="#"> ( 95 Reviews )</a>
                                </li>
                              </ul>
                            </div>
                            <h3>Digital Stethoscope</h3>
                            <div class="product-price">
                              <span>$149.00</span>
                              <del>$165.00</del>
                            </div>
                            <div class="modal-product-meta ltn__product-details-menu-1">
                              <ul>
                                <li>
                                  <strong>Categories:</strong>
                                  <span>
                                    <a href="#">Parts</a>
                                    <a href="#">Car</a>
                                    <a href="#">Seat</a>
                                    <a href="#">Cover</a>
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div class="ltn__product-details-menu-2">
                              <ul>
                                <li>
                                  <div class="cart-plus-minus">
                                    <input
                                      type="text"
                                      value="02"
                                      name="qtybutton"
                                      class="cart-plus-minus-box"
                                    />
                                  </div>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="theme-btn-1 btn btn-effect-1"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i class="fas fa-shopping-cart"></i>
                                    <span>ADD TO CART</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div class="ltn__product-details-menu-3">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    class=""
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i class="far fa-heart"></i>
                                    <span>Add to Wishlist</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class=""
                                    title="Compare"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i class="fas fa-exchange-alt"></i>
                                    <span>Compare</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <hr />
                            <div class="ltn__social-media">
                              <ul>
                                <li>Share:</li>
                                <li>
                                  <a href="#" title="Facebook">
                                    <i class="fab fa-facebook-f"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" title="Twitter">
                                    <i class="fab fa-twitter"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" title="Linkedin">
                                    <i class="fab fa-linkedin"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" title="Instagram">
                                    <i class="fab fa-instagram"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- MODAL AREA END --> */}

        {/* <!-- MODAL AREA START (Add To Cart Modal) --> */}
        <div class="ltn__modal-area ltn__add-to-cart-modal-area">
          <div class="modal fade" id="add_to_cart_modal" tabindex="-1">
            <div class="modal-dialog modal-md" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="ltn__quick-view-modal-inner">
                    <div class="modal-product-item">
                      <div class="row">
                        <div class="col-12">
                          <div class="modal-product-img">{/* $1 /> */}</div>
                          <div class="modal-product-info">
                            <h5>
                              <a href="#">Digital Stethoscope</a>
                            </h5>
                            <p class="added-cart">
                              <i class="fa fa-check-circle"></i> Successfully
                              added to your Cart
                            </p>
                            <div class="btn-wrapper">
                              <a href="#" class="theme-btn-1 btn btn-effect-1">
                                View Cart
                              </a>
                              <a href="#" class="theme-btn-2 btn btn-effect-2">
                                Checkout
                              </a>
                            </div>
                          </div>
                          {/* <!-- additional-info --> */}
                          <div class="additional-info d-none">
                            <p>
                              We want to give you <b>10% discount</b> for your
                              first order, <br /> Use discount code at checkout
                            </p>
                            <div class="payment-method">{/* $1 /> */}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- MODAL AREA END --> */}

        {/* <!-- MODAL AREA START (Wishlist Modal) --> */}
        <div class="ltn__modal-area ltn__add-to-cart-modal-area">
          <div class="modal fade" id="liton_wishlist_modal" tabindex="-1">
            <div class="modal-dialog modal-md" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="ltn__quick-view-modal-inner">
                    <div class="modal-product-item">
                      <div class="row">
                        <div class="col-12">
                          <div class="modal-product-img">
                            <img src="assets/img/product/7.png" alt="#" />
                          </div>
                          <div class="modal-product-info">
                            <h5>
                              <a href="#">Digital Stethoscope</a>
                            </h5>
                            <p class="added-cart">
                              <i class="fa fa-check-circle"></i> Successfully
                              added to your Wishlist
                            </p>
                            <div class="btn-wrapper">
                              <a href="#" class="theme-btn-1 btn btn-effect-1">
                                View Wishlist
                              </a>
                            </div>
                          </div>
                          {/* <!-- additional-info --> */}
                          <div class="additional-info d-none">
                            <p>
                              We want to give you <b>10% discount</b> for your
                              first order, <br /> Use discount code at checkout
                            </p>
                            <div class="payment-method">{/* $1 /> */}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- MODAL AREA END --> */}
      </div>

      <script src="js/plugins.js"></script>
      <script src="js/main.js"></script>
    </React.Fragment>
  );
};

export default ProductDetail;
