import React, { useEffect, useState } from "react";
import axios from "../state/axios-instance";
import Product from "../components/Product";

const Shop = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(9);
  const baseImageURL = "http://localhost:8000";

  // CATEGORY FILTER
  const filteredProducts = products.filter(
    (product) =>
      selectedCategory === null || product.category === selectedCategory
  );

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // PAGINATION VARIABLE
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const handleCategoryClick = (category) => {
    category === "null"
      ? setSelectedCategory(null)
      : setSelectedCategory(category);
    setCurrentPage(1);
  };

  useEffect(() => {
    axios
      .get("/product")
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => setCurrentPage(i)}
        >
          <a>{i}</a>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <React.Fragment>
      <div class="body-wrapper">
        {/* <!-- HEADER AREA START (header-3) --> */}
        {/* 
    position: sticky !important;
    top: 0;
    background: white;
    box-shadow: var(--ltn__box-shadow-1);
 */}
        <header class="ltn__header-area ltn__header-3 sticky-top bg-white shadow">
          {/* <!-- ltn__header-top-area end --> 
    <!-- ltn__header-middle-area start --> */}
          <div class="ltn__header-middle-area">
            <div class="container">
              <div class="row">
                <div class="col d-flex">
                  <div class="col site-logo">
                    <a href="/">
                      <img src="assets/img/logo.png" alt="Logo" />
                    </a>
                  </div>
                  <div class="col header-feature-item">
                    <div class="header-feature-icon">
                      <i class="icon-call"></i>
                    </div>
                    <div class="header-feature-info">
                      <h6>Phone</h6>
                      <p>
                        <a href="#">028.6292.5568</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col header-contact-serarch-column d-none d-lg-block">
                  <div class="header-contact-search">
                    <div className="col header-menu-column menu-color-white---">
                      <div className="header-menu d-none d-xl-block">
                        <nav>
                          <div className="ltn__main-menu">
                            <ul>
                              <li>
                                <a href="/">Trang chủ</a>
                              </li>
                              <li>
                                <a href="/shop">Sản phẩm</a>
                              </li>
                              <li>
                                <a href="/aboutUs">Liên hệ</a>
                              </li>
                            </ul>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- header-bottom-area end --> */}
        </header>
        {/* <!-- HEADER AREA END --> */}

        {/* <!-- MOBILE MENU START --> */}
        <div class="mobile-header-menu-fullwidth mb-30 d-block d-lg-none">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                {/* <!-- Mobile Menu Button --> */}
                <div class="mobile-menu-toggle d-lg-none">
                  <span>MENU</span>
                  <a
                    href="#ltn__utilize-mobile-menu"
                    class="ltn__utilize-toggle"
                  >
                    <svg viewBox="0 0 800 600">
                      <path
                        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                        id="top"
                      ></path>
                      <path d="M300,320 L540,320" id="middle"></path>
                      <path
                        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                        id="bottom"
                        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- MOBILE MENU END --> */}

        {/* <!-- Utilize Cart Menu Start --> */}
        <div
          id="ltn__utilize-cart-menu"
          class="ltn__utilize ltn__utilize-cart-menu"
        >
          <div class="ltn__utilize-menu-inner ltn__scrollbar">
            <div class="ltn__utilize-menu-head">
              <span class="ltn__utilize-menu-title">Cart</span>
              <button class="ltn__utilize-close">×</button>
            </div>
            <div class="mini-cart-product-area ltn__scrollbar">
              <div class="mini-cart-item clearfix">
                <div class="mini-cart-img">
                  <a href="#">
                    <img src="assets/img/product/1.png" alt="Image" />
                  </a>
                  <span class="mini-cart-item-delete">
                    <i class="icon-cancel"></i>
                  </span>
                </div>
                <div class="mini-cart-info">
                  <h6>
                    <a href="#">Antiseptic Spray</a>
                  </h6>
                  <span class="mini-cart-quantity">1 x $65.00</span>
                </div>
              </div>
              <div class="mini-cart-item clearfix">
                <div class="mini-cart-img">
                  <a href="#">
                    <img src="assets/img/product/2.png" alt="Image" />
                  </a>
                  <span class="mini-cart-item-delete">
                    <i class="icon-cancel"></i>
                  </span>
                </div>
                <div class="mini-cart-info">
                  <h6>
                    <a href="#">Digital Stethoscope</a>
                  </h6>
                  <span class="mini-cart-quantity">1 x $85.00</span>
                </div>
              </div>
              <div class="mini-cart-item clearfix">
                <div class="mini-cart-img">
                  <a href="#">
                    <img src="assets/img/product/3.png" alt="Image" />
                  </a>
                  <span class="mini-cart-item-delete">
                    <i class="icon-cancel"></i>
                  </span>
                </div>
                <div class="mini-cart-info">
                  <h6>
                    <a href="#">Cosmetic Containers</a>
                  </h6>
                  <span class="mini-cart-quantity">1 x $92.00</span>
                </div>
              </div>
              <div class="mini-cart-item clearfix">
                <div class="mini-cart-img">
                  <a href="#">
                    <img src="assets/img/product/4.png" alt="Image" />
                  </a>
                  <span class="mini-cart-item-delete">
                    <i class="icon-cancel"></i>
                  </span>
                </div>
                <div class="mini-cart-info">
                  <h6>
                    <a href="#">Thermometer Gun</a>
                  </h6>
                  <span class="mini-cart-quantity">1 x $68.00</span>
                </div>
              </div>
            </div>
            <div class="mini-cart-footer">
              <div class="mini-cart-sub-total">
                <h5>
                  Subtotal: <span>$310.00</span>
                </h5>
              </div>
              <div class="btn-wrapper">
                <a href="#">View Cart</a>
                <a href="#">Checkout</a>
              </div>
              <p>Free Shipping on All Orders Over $100!</p>
            </div>
          </div>
        </div>
        {/* <!-- Utilize Cart Menu End --> */}

        {/* <!-- Utilize Mobile Menu Start --> */}
        <div
          id="ltn__utilize-mobile-menu"
          class="ltn__utilize ltn__utilize-mobile-menu"
        >
          <div class="ltn__utilize-menu-inner ltn__scrollbar">
            <div class="ltn__utilize-menu-head">
              <div class="site-logo">
                <a href="#">
                  <img src="assets/img/logo.png" alt="Logo" />
                </a>
              </div>
              <button class="ltn__utilize-close">×</button>
            </div>
            <div class="ltn__utilize-menu-search-form">
              <form action="#">
                <input type="text" placeholder="Search..." />
                <button>
                  <i class="fas fa-search"></i>
                </button>
              </form>
            </div>
            <div class="ltn__utilize-menu">
              <ul>
                <li>
                  <a href="#">Home</a>
                  <ul class="sub-menu">
                    <li>
                      <a href="#">Home Style 01</a>
                    </li>
                    <li>
                      <a href="#">Home Style 02</a>
                    </li>
                    <li>
                      <a href="#">Home Style 03</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">About</a>
                  <ul class="sub-menu">
                    <li>
                      <a href="#">About</a>
                    </li>
                    <li>
                      <a href="#">Services</a>
                    </li>
                    <li>
                      <a href="#">Service Details</a>
                    </li>
                    <li>
                      <a href="#">Portfolio</a>
                    </li>
                    <li>
                      <a href="#">Portfolio - 02</a>
                    </li>
                    <li>
                      <a href="#">Portfolio Details</a>
                    </li>
                    <li>
                      <a href="#">Team</a>
                    </li>
                    <li>
                      <a href="#">Team Details</a>
                    </li>
                    <li>
                      <a href="#">FAQ</a>
                    </li>
                    <li>
                      <a href="#">Google Map Locations</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Shop</a>
                  <ul class="sub-menu">
                    <li>
                      <a href="#">Shop</a>
                    </li>
                    <li>
                      <a href="#">Shop Grid</a>
                    </li>
                    <li>
                      <a href="#">Shop Left sidebar</a>
                    </li>
                    <li>
                      <a href="#">Shop right sidebar</a>
                    </li>
                    <li>
                      <a href="#">Shop details </a>
                    </li>
                    <li>
                      <a href="#">Cart</a>
                    </li>
                    <li>
                      <a href="#">Wishlist</a>
                    </li>
                    <li>
                      <a href="#">Checkout</a>
                    </li>
                    <li>
                      <a href="#">Order Tracking</a>
                    </li>
                    <li>
                      <a href="#">My Account</a>
                    </li>
                    <li>
                      <a href="#">Sign in</a>
                    </li>
                    <li>
                      <a href="#">Register</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">News</a>
                  <ul class="sub-menu">
                    <li>
                      <a href="#">News</a>
                    </li>
                    <li>
                      <a href="#">News Grid</a>
                    </li>
                    <li>
                      <a href="#">News Left sidebar</a>
                    </li>
                    <li>
                      <a href="#">News Right sidebar</a>
                    </li>
                    <li>
                      <a href="#">News details</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Pages</a>
                  <ul class="sub-menu">
                    <li>
                      <a href="#">About</a>
                    </li>
                    <li>
                      <a href="#">Services</a>
                    </li>
                    <li>
                      <a href="#">Service Details</a>
                    </li>
                    <li>
                      <a href="#">Portfolio</a>
                    </li>
                    <li>
                      <a href="#">Portfolio - 02</a>
                    </li>
                    <li>
                      <a href="#">Portfolio Details</a>
                    </li>
                    <li>
                      <a href="#">Team</a>
                    </li>
                    <li>
                      <a href="#">Team Details</a>
                    </li>
                    <li>
                      <a href="#">FAQ</a>
                    </li>
                    <li>
                      <a href="#">History</a>
                    </li>
                    <li>
                      <a href="#">Add Listing</a>
                    </li>
                    <li>
                      <a href="#">Google Map Locations</a>
                    </li>
                    <li>
                      <a href="#">404</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>
                    <li>
                      <a href="#">Coming Soon</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
              </ul>
            </div>
            <div class="ltn__utilize-buttons ltn__utilize-buttons-2">
              <ul>
                <li>
                  <a href="#">
                    <span class="utilize-btn-icon">
                      <i class="far fa-user"></i>
                    </span>
                    My Account
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span class="utilize-btn-icon">
                      <i class="far fa-heart"></i>
                      <sup>3</sup>
                    </span>
                    Wishlist
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span class="utilize-btn-icon">
                      <i class="fas fa-shopping-cart"></i>
                      <sup>5</sup>
                    </span>
                    Shoping Cart
                  </a>
                </li>
              </ul>
            </div>
            <div class="ltn__social-media-2">
              <ul>
                <li>
                  <a href="#" title="Facebook">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#" title="Twitter">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#" title="Linkedin">
                    <i class="fab fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href="#" title="Instagram">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <!-- Utilize Mobile Menu End --> */}

        <div class="ltn__utilize-overlay"></div>

        {/* <!-- BREADCRUMB AREA START --> */}
        <div
          class="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image"
          style={{
            // backgroundImage: "url('/assets/img/bg/11.jpg')",
            // width: "100%",
            // position: "relative",
            // left: "0px",
            // top: "0px",
            // zIndex: "999",
            // opacity: "1",
            position: "relative",
            width: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              opacity: "1",
              zIndex: -1,
              backgroundImage: "url('/assets/img/bg/11.jpg')",
              backgroundPosition: "center",
              backgroundSize: "cover",
              filter: "blur(8px)",
            }}
          ></div>
          <div
            class="container"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div class="row">
              <div class="col-lg-12">
                <div
                  class="ltn__breadcrumb-inner"
                  style={{
                    position: "relative",
                    color: "#ffffff",
                    float: "left",
                  }}
                >
                  <h1 class="page-title">SẢN PHẨM</h1>
                  <div class="ltn__breadcrumb-list">
                    <ul
                      style={{
                        color: "black",
                      }}
                    >
                      <li>
                        <a href="#">
                          <span class="ltn__secondary-color">
                            <i class="fas fa-home"></i>
                          </span>{" "}
                          Trang chủ
                        </a>
                      </li>
                      <li>Sản phẩm</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- BREADCRUMB AREA END --> */}

        {/* <!-- PRODUCT DETAILS AREA START --> */}
        <div class="ltn__product-area ltn__product-gutter">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 order-lg-2 mb-120">
                {/* <div class="ltn__shop-options">
                  <ul>
                    <li>
                      <div class="ltn__grid-list-tab-menu ">
                        <div class="nav">
                          <a
                            class="active show"
                            data-bs-toggle="tab"
                            href="#liton_product_grid"
                          >
                            <i class="fas fa-th-large"></i>
                          </a>
                          <a data-bs-toggle="tab" href="#liton_product_list">
                            <i class="fas fa-list"></i>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="showing-product-number text-right">
                        <span>Showing 1–12 of 18 results</span>
                      </div>
                    </li>
                    <li>
                      <div class="short-by text-center">
                        <select class="nice-select">
                          <option>Default Sorting</option>
                          <option>Sort by popularity</option>
                          <option>Sort by new arrivals</option>
                          <option>Sort by price: low to high</option>
                          <option>Sort by price: high to low</option>
                        </select>
                      </div>
                    </li>
                  </ul>
                </div> */}
                <div class="tab-content">
                  <div
                    class="tab-pane fade active show"
                    id="liton_product_grid"
                  >
                    <div class="ltn__product-tab-content-inner ltn__product-grid-view">
                      <div class="row">
                        {currentProducts.map((product) => (
                          <Product key={product.id} product={product} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div aria-label="Page pagination">
                  <ul class="pagination justify-content-center">
                    <li></li>
                  </ul>
                </div> */}
                <div class="ltn__pagination-area text-center">
                  <div class="ltn__pagination">
                    <ul>
                      <li>
                        <a onClick={prevPage}>
                          <i class="fas fa-angle-double-left"></i>
                        </a>
                      </li>
                      {renderPageNumbers()}
                      <li>
                        <a onClick={nextPage}>
                          <i class="fas fa-angle-double-right"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-4  mb-120">
                <aside class="sidebar ltn__shop-sidebar ltn__right-sidebar">
                  {/* <!-- Category Widget --> */}
                  <div class="widget ltn__menu-widget">
                    <h4 class="ltn__widget-title ltn__widget-title-border">
                      Danh Mục
                    </h4>
                    <ul>
                      <li>
                        <button
                          onClick={() => handleCategoryClick("null")}
                          className="text-button"
                        >
                          <div>Tất cả</div>
                          <span class="float-end">
                            <i class="fas fa-long-arrow-alt-right"></i>
                          </span>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleCategoryClick("ELISA")}
                          className="text-button"
                        >
                          <div>ELISA</div>
                          <span class="float-end">
                            <i class="fas fa-long-arrow-alt-right"></i>
                          </span>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleCategoryClick("PCR")}
                          className="text-button"
                        >
                          <div>PCR</div>
                          <span class="float-end">
                            <i class="fas fa-long-arrow-alt-right"></i>
                          </span>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleCategoryClick("TEST")}
                          className="text-button"
                        >
                          <div>TEST THỬ NHANH</div>
                          <span className="float-end">
                            <i class="fas fa-long-arrow-alt-right"></i>
                          </span>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleCategoryClick("BEAUTY")}
                          class="text-button"
                        >
                          <div>LÀM ĐẸP</div>
                          <span class="float-end">
                            <i class="fas fa-long-arrow-alt-right"></i>
                          </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- Price Filter Widget --> */}
                  {/* <div class="widget ltn__price-filter-widget">
                    <h4 class="ltn__widget-title ltn__widget-title-border">
                      Filter by price
                    </h4>
                    <div class="price_filter">
                      <div class="price_slider_amount">
                        <input type="submit" value="Your range:" />
                        <input
                          type="text"
                          class="amount"
                          name="price"
                          placeholder="Add Your Price"
                        />
                      </div>
                      <div class="slider-range"></div>
                    </div>
                  </div> */}

                  {/* <!-- Top Rated Product Widget --> */}
                  {/* <div class="widget ltn__top-rated-product-widget">
                    <h4 class="ltn__widget-title ltn__widget-title-border">
                      Top Rated Product
                    </h4>
                    <ul>
                      <li>
                        <div class="top-rated-product-item clearfix">
                          <div class="top-rated-product-img">
                            <a href="#">
                              <img src="assets/img/product/1.png" alt="#" />
                            </a>
                          </div>
                          <div class="top-rated-product-info">
                            <div class="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h6>
                              <a href="#">Mixel Solid Seat Cover</a>
                            </h6>
                            <div class="product-price">
                              <span>$49.00</span>
                              <del>$65.00</del>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="top-rated-product-item clearfix">
                          <div class="top-rated-product-img">
                            <a href="#">
                              <img src="assets/img/product/2.png" alt="#" />
                            </a>
                          </div>
                          <div class="top-rated-product-info">
                            <div class="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h6>
                              <a href="#">Thermometer Gun</a>
                            </h6>
                            <div class="product-price">
                              <span>$49.00</span>
                              <del>$65.00</del>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="top-rated-product-item clearfix">
                          <div class="top-rated-product-img">
                            <a href="#">
                              <img src="assets/img/product/3.png" alt="#" />
                            </a>
                          </div>
                          <div class="top-rated-product-info">
                            <div class="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h6>
                              <a href="#">Coil Spring Conversion</a>
                            </h6>
                            <div class="product-price">
                              <span>$49.00</span>
                              <del>$65.00</del>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div> */}

                  {/* <!-- Search Widget --> */}
                  {/* <div class="widget ltn__search-widget">
                    <h4 class="ltn__widget-title ltn__widget-title-border">
                      Search Objects
                    </h4>
                    <form action="#">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search your keyword..."
                      />
                      <button type="submit">
                        <i class="fas fa-search"></i>
                      </button>
                    </form>
                  </div> */}

                  {/* <!-- Tagcloud Widget --> */}
                  {/* <div class="widget ltn__tagcloud-widget">
                    <h4 class="ltn__widget-title ltn__widget-title-border">
                      Popular Tags
                    </h4>
                    <ul>
                      <li>
                        <a href="#">Body</a>
                      </li>
                      <li>
                        <a href="#">Doctor</a>
                      </li>
                      <li>
                        <a href="#">Drugs</a>
                      </li>
                      <li>
                        <a href="#">Eye</a>
                      </li>
                      <li>
                        <a href="#">Face</a>
                      </li>
                      <li>
                        <a href="#">Hand</a>
                      </li>
                      <li>
                        <a href="#">Mask</a>
                      </li>
                      <li>
                        <a href="#">Medicine</a>
                      </li>
                      <li>
                        <a href="#">Price</a>
                      </li>
                      <li>
                        <a href="#">Sanitizer</a>
                      </li>
                      <li>
                        <a href="#">Virus</a>
                      </li>
                    </ul>
                  </div> */}

                  {/* <!-- Size Widget --> */}
                  {/* <div class="widget ltn__tagcloud-widget ltn__size-widget">
                    <h4 class="ltn__widget-title ltn__widget-title-border">
                      Product Size
                    </h4>
                    <ul>
                      <li>
                        <a href="#">S</a>
                      </li>
                      <li>
                        <a href="#">M</a>
                      </li>
                      <li>
                        <a href="#">L</a>
                      </li>
                      <li>
                        <a href="#">XL</a>
                      </li>
                      <li>
                        <a href="#">XXL</a>
                      </li>
                    </ul>
                  </div> */}

                  {/* <!-- Color Widget --> */}
                  {/* <div class="widget ltn__color-widget">
                    <h4 class="ltn__widget-title ltn__widget-title-border">
                      Product Color
                    </h4>
                    <ul>
                      <li class="black">
                        <a href="#"></a>
                      </li>
                      <li class="white">
                        <a href="#"></a>
                      </li>
                      <li class="red">
                        <a href="#"></a>
                      </li>
                      <li class="silver">
                        <a href="#"></a>
                      </li>
                      <li class="gray">
                        <a href="#"></a>
                      </li>
                      <li class="maroon">
                        <a href="#"></a>
                      </li>
                      <li class="yellow">
                        <a href="#"></a>
                      </li>
                      <li class="olive">
                        <a href="#"></a>
                      </li>
                      <li class="lime">
                        <a href="#"></a>
                      </li>
                      <li class="green">
                        <a href="#"></a>
                      </li>
                      <li class="aqua">
                        <a href="#"></a>
                      </li>
                      <li class="teal">
                        <a href="#"></a>
                      </li>
                      <li class="blue">
                        <a href="#"></a>
                      </li>
                      <li class="navy">
                        <a href="#"></a>
                      </li>
                      <li class="fuchsia">
                        <a href="#"></a>
                      </li>
                      <li class="purple">
                        <a href="#"></a>
                      </li>
                      <li class="pink">
                        <a href="#"></a>
                      </li>
                      <li class="nude">
                        <a href="#"></a>
                      </li>
                      <li class="orange">
                        <a href="#"></a>
                      </li>

                      <li>
                        <a href="#" class="orange"></a>
                      </li>
                      <li>
                        <a href="#" class="orange"></a>
                      </li>
                    </ul>
                  </div> */}

                  {/* <!-- Banner Widget --> */}
                  {/* <div class="widget ltn__banner-widget">
                    <a href="#">
                      <img src="assets/img/banner/banner-2.jpg" alt="#" />
                    </a>
                  </div> */}
                </aside>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- PRODUCT DETAILS AREA END --> */}

        {/* <!-- FOOTER AREA START --> */}
        <footer class="ltn__footer-area  ">
          <div class="footer-top-area  section-bg-2 plr--5">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-4 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-about-widget">
                    {/* <div class="footer-logo">
                      <div class="site-logo">
                        <img src="assets/img/logo.png" alt="Logo" />
                      </div>
                    </div> */}
                    <h4 class="footer-title">VỀ HNM</h4>

                    <p>
                      HoangNhatMinh luôn tự hào là nhà tiên phong trong lĩnh vực
                      thuốc với nhiều sáng tạo và sản phẩm đã được công nhận bởi
                      bộ y tế về an toàn và hiệu quả đem đến cho người sử dụng.
                    </p>

                    <div class="ltn__social-media mt-20">
                      <ul>
                        <li>
                          <a href="#" title="Facebook">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Twitter">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Linkedin">
                            <i class="fab fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Youtube">
                            <i class="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-menu-widget clearfix">
                    <h4 class="footer-title">HỖ TRỢ</h4>
                    <div class="footer-menu">
                      <ul>
                        <li>
                          <a>FAQ</a>
                        </li>
                        <li>
                          <a>Chính sách bảo mật</a>
                        </li>
                        <li>
                          <a>Chính sách thanh toán</a>
                        </li>
                        <li>
                          <a>Chính sách giao hàng</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-menu-widget clearfix">
                    <h4 class="footer-title">SHOP</h4>
                    <div class="footer-menu">
                      <ul>
                        <li>
                          <a href="/shop">ELISA</a>
                        </li>
                        <li>
                          <a href="/shop">PCR</a>
                        </li>
                        <li>
                          <a href="/shop">Test thử nhanh</a>
                        </li>
                        <li>
                          <a href="/shop">Làm đẹp</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-newsletter-widget">
                    <h4 class="footer-title">LIÊN HỆ</h4>
                    <div class="footer-address">
                      <ul>
                        <li>
                          <div class="footer-address-icon">
                            <i class="icon-placeholder"></i>
                          </div>
                          <div class="footer-address-info">
                            <p>72 Đường C18, Tân Bình, Tp. Hồ Chí Minh</p>
                          </div>
                        </li>
                        <li>
                          <div class="footer-address-icon">
                            <i class="icon-call"></i>
                          </div>
                          <div class="footer-address-info">
                            <p>
                              <a href="tel:+02862925568">(028).6292.5568</a>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div class="footer-address-icon">
                            <i class="icon-mail"></i>
                          </div>
                          <div class="footer-address-info">
                            <p>
                              <a href="mailto:example@example.com">
                                hoangnhatminh@lab.com
                              </a>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    {/* <img
                      class="mt-30"
                      src="assets/img/logo-bct.png"
                      alt="Payment Image"
                      width={150}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
            <div class="container-fluid ltn__border-top-2">
              <div class="row">
                <div class="col-md-12 col-12 text-center">
                  <div class="ltn__copyright-design clearfix">
                    <p>
                      All Rights Reserved @ Hoang Nhat Minh{" "}
                      <span class="current-year"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* <!-- FOOTER AREA END --> */}

        {/* <!-- MODAL AREA START (Quick View Modal) --> */}
        <div class="ltn__modal-area ltn__quick-view-modal-area">
          <div class="modal fade" id="quick_view_modal" tabindex="-1">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    {/* <!-- <i class="fas fa-times"></i> --> */}
                  </button>
                </div>
                <div class="modal-body">
                  <div class="ltn__quick-view-modal-inner">
                    <div class="modal-product-item">
                      <div class="row">
                        <div class="col-lg-6 col-12">
                          <div class="modal-product-img">
                            <img src="assets/img/product/4.png" alt="#" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-12">
                          <div class="modal-product-info">
                            <div class="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="far fa-star"></i>
                                  </a>
                                </li>
                                <li class="review-total">
                                  {" "}
                                  <a href="#"> ( 95 Reviews )</a>
                                </li>
                              </ul>
                            </div>
                            <h3>Digital Stethoscope</h3>
                            <div class="product-price">
                              <span>$149.00</span>
                              <del>$165.00</del>
                            </div>
                            <div class="modal-product-meta ltn__product-details-menu-1">
                              <ul>
                                <li>
                                  <strong>Categories:</strong>
                                  <span>
                                    <a href="#">Parts</a>
                                    <a href="#">Car</a>
                                    <a href="#">Seat</a>
                                    <a href="#">Cover</a>
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div class="ltn__product-details-menu-2">
                              <ul>
                                <li>
                                  <div class="cart-plus-minus">
                                    <input
                                      type="text"
                                      value="02"
                                      name="qtybutton"
                                      class="cart-plus-minus-box"
                                    />
                                  </div>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="theme-btn-1 btn btn-effect-1"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i class="fas fa-shopping-cart"></i>
                                    <span>ADD TO CART</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div class="ltn__product-details-menu-3">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    class=""
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i class="far fa-heart"></i>
                                    <span>Add to Wishlist</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class=""
                                    title="Compare"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i class="fas fa-exchange-alt"></i>
                                    <span>Compare</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <hr />
                            <div class="ltn__social-media">
                              <ul>
                                <li>Share:</li>
                                <li>
                                  <a href="#" title="Facebook">
                                    <i class="fab fa-facebook-f"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" title="Twitter">
                                    <i class="fab fa-twitter"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" title="Linkedin">
                                    <i class="fab fa-linkedin"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" title="Instagram">
                                    <i class="fab fa-instagram"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- MODAL AREA END --> */}

        {/* <!-- MODAL AREA START (Add To Cart Modal) --> */}
        <div class="ltn__modal-area ltn__add-to-cart-modal-area">
          <div class="modal fade" id="add_to_cart_modal" tabindex="-1">
            <div class="modal-dialog modal-md" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="ltn__quick-view-modal-inner">
                    <div class="modal-product-item">
                      <div class="row">
                        <div class="col-12">
                          <div class="modal-product-img">
                            <img src="assets/img/product/1.png" alt="#" />
                          </div>
                          <div class="modal-product-info">
                            <h5>
                              <a href="#">Digital Stethoscope</a>
                            </h5>
                            <p class="added-cart">
                              <i class="fa fa-check-circle"></i> Successfully
                              added to your Cart
                            </p>
                            <div class="btn-wrapper">
                              <a href="#" class="theme-btn-1 btn btn-effect-1">
                                View Cart
                              </a>
                              <a href="#" class="theme-btn-2 btn btn-effect-2">
                                Checkout
                              </a>
                            </div>
                          </div>
                          {/* <!-- additional-info --> */}
                          <div class="additional-info d-none">
                            <p>
                              We want to give you <b>10% discount</b> for your
                              first order, <br /> Use discount code at checkout
                            </p>
                            <div class="payment-method">
                              <img src="assets/img/icons/payment.png" alt="#" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- MODAL AREA END --> */}

        {/* <!-- MODAL AREA START (Wishlist Modal) --> */}
        <div class="ltn__modal-area ltn__add-to-cart-modal-area">
          <div class="modal fade" id="liton_wishlist_modal" tabindex="-1">
            <div class="modal-dialog modal-md" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="ltn__quick-view-modal-inner">
                    <div class="modal-product-item">
                      <div class="row">
                        <div class="col-12">
                          <div class="modal-product-img">
                            <img src="assets/img/product/7.png" alt="#" />
                          </div>
                          <div class="modal-product-info">
                            <h5>
                              <a href="#">Digital Stethoscope</a>
                            </h5>
                            <p class="added-cart">
                              <i class="fa fa-check-circle"></i> Successfully
                              added to your Wishlist
                            </p>
                            <div class="btn-wrapper">
                              <a href="#" class="theme-btn-1 btn btn-effect-1">
                                View Wishlist
                              </a>
                            </div>
                          </div>
                          {/* <!-- additional-info --> */}
                          <div class="additional-info d-none">
                            <p>
                              We want to give you <b>10% discount</b> for your
                              first order, <br /> Use discount code at checkout
                            </p>
                            <div class="payment-method">
                              <img src="assets/img/icons/payment.png" alt="#" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- MODAL AREA END --> */}
      </div>

      <script src="js/plugins.js"></script>
      <script src="js/main.js"></script>
    </React.Fragment>
  );
};

export default Shop;
