import React, { useEffect, useState } from "react";

const Home = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   document.title = "CTY Hoang Nhat Minh"; // Update the title here
  // }, []);

  return (
    <React.Fragment>
      <div className="body-wrapper">
        {/* <!-- HEADER AREA START (header-5) --> */}
        <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile--- ltn__header-logo-and-mobile-menu--- ltn__header-transparent gradient-color-4---">
          {/* <!-- ltn__header-top-area start -->
        <!-- <div className="ltn__header-top-area border-bottom top-area-color-white---">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div className="ltn__top-bar-menu">
                            <ul>
                                <li><a href="mailto:info@webmail.com?Subject=Flower%20greetings%20to%20you"><i className="icon-mail"></i> info@webmail.com</a></li>
                                <li><a href="#"><i className="icon-placeholder"></i> 15/A, Nest Tower, NYC</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="top-bar-right text-end">
                            <div className="ltn__top-bar-menu">
                                <ul>
                                    <li>
                                        <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                                            <ul>
                                                <li><a href="#" className="dropdown-toggle"><span className="active-currency">English</span></a>
                                                    <ul>
                                                        <li><a href="#">Arabic</a></li>
                                                        <li><a href="#">Bengali</a></li>
                                                        <li><a href="#">Chinese</a></li>
                                                        <li><a href="#">English</a></li>
                                                        <li><a href="#">French</a></li>
                                                        <li><a href="#">Hindi</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="ltn__social-media">
                                            <ul>
                                                <li><a href="#" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                                                
                                                <li><a href="#" title="Instagram"><i className="fab fa-instagram"></i></a></li>
                                                <li><a href="#" title="Dribbble"><i className="fab fa-dribbble"></i></a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> --> */}
          {/* <!-- ltn__header-top-area end --> */}
          {/* <!-- ltn__header-middle-area start --> */}
          <div className="ltn__header-middle-area ltn__logo-right-menu-option ltn__header-row-bg-white ltn__header-padding ltn__header-sticky ltn__sticky-bg-white">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="site-logo-wrap">
                    <div className="site-logo">
                      <a href="#">
                        <img src="assets/img/logo.png" alt="Logo" />
                      </a>
                    </div>
                    <div className="get-support clearfix d-none">
                      <div className="get-support-icon">
                        <i className="icon-call"></i>
                      </div>
                      <div className="get-support-info">
                        <h6>Get Support</h6>
                        <h4>
                          <a href="#">123-456-789-10</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col header-menu-column menu-color-white---">
                  <div className="header-menu d-none d-xl-block">
                    <nav>
                      <div className="ltn__main-menu">
                        <ul>
                          <li>
                            <a href="#">Trang chủ</a>
                          </li>
                          <li>
                            <a href="/shop">Sản phẩm</a>
                          </li>
                          <li>
                            <a href="/aboutUs">Liên hệ</a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="col--- ltn__header-options ltn__header-options-2 mb-sm-20">
                  {/* <!-- header-search-1 -->
                        <!-- <div className="header-search-wrap">
                            <div className="header-search-1">
                                <div className="search-icon">
                                    <i className="icon-search for-search-show"></i>
                                    <i className="icon-cancel  for-search-close"></i>
                                </div>
                            </div>
                            <div className="header-search-1-form">
                                <form id="#" method="get"  action="#">
                                    <input type="text" name="search" value="" placeholder="Search here..."/>
                                    <button type="submit">
                                        <span><i className="icon-search"></i></span>
                                    </button>
                                </form>
                            </div>
                        </div> -->
                        <!-- user-menu -->
                        <!-- <div className="ltn__drop-menu user-menu">
                            <ul>
                                <li>
                                    <a href="#"><i className="icon-user"></i></a>
                                    <ul>
                                        <li><a href="#">Sign in</a></li>
                                        <li><a href="#">Register</a></li>
                                        <li><a href="#">My Account</a></li>
                                        <li><a href="#">Wishlist</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div> -->
                        <!-- mini-cart -->
                        <!-- <div className="mini-cart-icon">
                            <a href="#ltn__utilize-cart-menu" className="ltn__utilize-toggle">
                                <i className="icon-shopping-cart"></i>
                                <sup>2</sup>
                            </a>
                        </div> -->
                        <!-- mini-cart -->
                        <!-- Mobile Menu Button -->
                        <!-- <div className="mobile-menu-toggle d-xl-none">
                            <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
                                <svg viewBox="0 0 800 600">
                                    <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                    <path d="M300,320 L540,320" id="middle"></path>
                                    <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                                </svg>
                            </a>
                        </div> --> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- ltn__header-middle-area end --> */}
        </header>
        {/* <!-- HEADER AREA END --> */}

        {/* <!-- Utilize Cart Menu Start --> */}
        <div
          id="ltn__utilize-cart-menu"
          className="ltn__utilize ltn__utilize-cart-menu"
        >
          <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
              <span className="ltn__utilize-menu-title">Cart</span>
              <button className="ltn__utilize-close">×</button>
            </div>
            <div className="mini-cart-product-area ltn__scrollbar">
              <div className="mini-cart-item clearfix">
                <div className="mini-cart-img">
                  <a href="#">
                    <img src="assets/img/product/1.png" alt="Image" />
                  </a>
                  <span className="mini-cart-item-delete">
                    <i className="icon-cancel"></i>
                  </span>
                </div>
                <div className="mini-cart-info">
                  <h6>
                    <a href="#">Antiseptic Spray</a>
                  </h6>
                  <span className="mini-cart-quantity">1 x $65.00</span>
                </div>
              </div>
              <div className="mini-cart-item clearfix">
                <div className="mini-cart-img">
                  <a href="#">
                    <img src="assets/img/product/2.png" alt="Image" />
                  </a>
                  <span className="mini-cart-item-delete">
                    <i className="icon-cancel"></i>
                  </span>
                </div>
                <div className="mini-cart-info">
                  <h6>
                    <a href="#">Digital Stethoscope</a>
                  </h6>
                  <span className="mini-cart-quantity">1 x $85.00</span>
                </div>
              </div>
              <div className="mini-cart-item clearfix">
                <div className="mini-cart-img">
                  <a href="#">
                    <img src="assets/img/product/3.png" alt="Image" />
                  </a>
                  <span className="mini-cart-item-delete">
                    <i className="icon-cancel"></i>
                  </span>
                </div>
                <div className="mini-cart-info">
                  <h6>
                    <a href="#">Cosmetic Containers</a>
                  </h6>
                  <span className="mini-cart-quantity">1 x $92.00</span>
                </div>
              </div>
              <div className="mini-cart-item clearfix">
                <div className="mini-cart-img">
                  <a href="#">
                    <img src="assets/img/product/4.png" alt="Image" />
                  </a>
                  <span className="mini-cart-item-delete">
                    <i className="icon-cancel"></i>
                  </span>
                </div>
                <div className="mini-cart-info">
                  <h6>
                    <a href="#">Thermometer Gun</a>
                  </h6>
                  <span className="mini-cart-quantity">1 x $68.00</span>
                </div>
              </div>
            </div>
            <div className="mini-cart-footer">
              <div className="mini-cart-sub-total">
                <h5>
                  Subtotal: <span>$310.00</span>
                </h5>
              </div>
              <div className="btn-wrapper">
                <a href="#" className="theme-btn-1 btn btn-effect-1">
                  View Cart
                </a>
                <a href="#" className="theme-btn-2 btn btn-effect-2">
                  Checkout
                </a>
              </div>
              <p>Free Shipping on All Orders Over $100!</p>
            </div>
          </div>
        </div>
        {/* <!-- Utilize Cart Menu End --> */}

        {/* <!-- Utilize Mobile Menu Start --> */}
        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
              <div className="site-logo">
                <a href="#">
                  <img
                    src="assets/img/logo.png"
                    alt="Logo"
                    style={{ maxWidth: "70%" }}
                  />
                </a>
              </div>
              <button className="ltn__utilize-close">×</button>
            </div>
            <div className="ltn__utilize-menu-search-form">
              <form action="#">
                <input type="text" placeholder="Search..." />
                <button>
                  <i className="fas fa-search"></i>
                </button>
              </form>
            </div>
            <div className="ltn__utilize-menu">
              <ul>
                <li>
                  <a href="#">Trang chủ</a>
                </li>
                <li>
                  <a href="/shop">Sản phẩm</a>
                </li>
                <li>
                  <a href="/aboutUs">Liên hệ</a>
                </li>
              </ul>
            </div>
            <div className="ltn__utilize-buttons ltn__utilize-buttons-2">
              <ul>
                <li>
                  <a href="#" title="My Account">
                    <span className="utilize-btn-icon">
                      <i className="far fa-user"></i>
                    </span>
                    My Account
                  </a>
                </li>
                <li>
                  <a href="#" title="Wishlist">
                    <span className="utilize-btn-icon">
                      <i className="far fa-heart"></i>
                      <sup>3</sup>
                    </span>
                    Wishlist
                  </a>
                </li>
                <li>
                  <a href="#" title="Shoping Cart">
                    <span className="utilize-btn-icon">
                      <i className="fas fa-shopping-cart"></i>
                      <sup>5</sup>
                    </span>
                    Shoping Cart
                  </a>
                </li>
              </ul>
            </div>
            <div className="ltn__social-media-2">
              <ul>
                <li>
                  <a href="#" title="Facebook">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#" title="Twitter">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#" title="Linkedin">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href="#" title="Instagram">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <!-- Utilize Mobile Menu End --> */}

        <div className="ltn__utilize-overlay"></div>

        {/* <!-- SLIDER AREA START (slider-3) --> */}
        <div className="ltn__slider-area ltn__slider-3  section-bg-1">
          <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1 slick-initialized slick-slider slick-dotted">
            {/* <!-- ltn__slide-item --> */}
            {/* <div
              className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60---"
              data-bs-bg="assets/img/slider/13.jpg"
            > */}

            <div aria-live="polite" className="slick-list draggable">
              <div
                class="slick-track"
                style={{ opacity: "1", width: "100%" }}
                role="listbox"
              >
                <div
                  className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60--- slick-slide slick-current slick-active"
                  style={{
                    backgroundImage: "url('/assets/img/slider/11.jpg')",
                    width: "100%",
                    position: "relative",
                    left: "0px",
                    top: "0px",
                    zIndex: "999",
                    opacity: "1",
                  }}
                >
                  <div className="ltn__slide-item-inner  text-left">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div
                            className="slide-item-info"
                            style={{ textAlign: "left" }}
                          >
                            <div className="slide-item-info-inner ltn__slide-animation">
                              {/* <h6 className="slide-sub-title white-color--- animated">
                                <span>
                                  <i className="fas fa-syringe"></i>
                                </span>{" "}
                                100% From the USA and Germany
                              </h6> */}
                              <h1 className="slide-title animated ">
                                THIẾT BỊ Y TẾ
                                <br />
                                NHẬP KHẨU
                                <br />
                                CHÍNH HÃNG
                              </h1>
                              {/* <div className="slide-brief animated">
                                <p>100% từ Mỹ và Đức</p>
                              </div> */}
                              <div className="btn-wrapper animated">
                                <a
                                  href="#"
                                  className="theme-btn-1 btn btn-success btn-lg"
                                  style={{
                                    backgroundColor:
                                      "var(--ltn__secondary-color) !important",
                                  }}
                                >
                                  Mua ngay
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- ltn__slide-item --> */}
            {/* <div
              className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60---"
              data-bs-bg="assets/img/slider/11.jpg"
            >
              <div className="ltn__slide-item-inner text-center">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <div className="slide-video mb-50 d-none">
                            <a
                              className="ltn__video-icon-2 ltn__video-icon-2-border"
                              href="https://www.youtube.com/embed/tlThdr3O5Qo"
                              data-rel="lightcase:myCollection"
                            >
                              <i className="fa fa-play"></i>
                            </a>
                          </div>
                          <h6 className="slide-sub-title white-color--- animated">
                            <span>
                              <i className="fas fa-syringe"></i>
                            </span>{" "}
                            100% genuine Products
                          </h6>
                          <h1 className="slide-title animated ">
                            Get 20% Discount <br />
                            Of N95 Mask
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore.
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <a
                              href="#"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              Shop Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <!-- ltn__slide-item --> */}
            {/* <div
              className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60---"
              data-bs-bg="assets/img/slider/12.jpg"
            >
              <div className="ltn__slide-item-inner  text-right text-end">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <h6 className="slide-sub-title white-color--- animated">
                            <span>
                              <i className="fas fa-syringe"></i>
                            </span>{" "}
                            100% genuine Products
                          </h6>
                          <h1 className="slide-title animated ">
                            Get 20% Discount <br />
                            Of N95 Mask
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore.
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <a
                              href="#"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              Shop Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <!--  --> */}
          </div>
        </div>
        {/* <!-- SLIDER AREA END --> */}

        {/* <!-- ABOUT US AREA START --> */}
        <div className="ltn__about-us-area pt-25 pb-120 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="about-us-img-wrap about-img-left">
                  <img src="assets/img/others/9.png" alt="About Us Image" />
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div
                  className="about-us-info-wrap"
                  style={{ textAlign: "left" }}
                >
                  <div className="section-title-area ltn__section-title-2--- mb-30">
                    <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color d-none">
                      About Us
                    </h6>
                    <h1 className="section-title">Đối tác tin cậy của bạn</h1>
                    <p>
                      <strong>CTY TNHH MTV TM&DV Y TẾ HOÀNG NHẬT MINH</strong>{" "}
                      được thành lập từ tháng 10 năm 2007, chúng tôi chuyên cung
                      cấp những mặt hàng độc quyền của Đức và Mỹ. Với phương
                      châm hoạt động “ Thành công của bạn là thành công của
                      chúng tôi ”, mục tiêu chính là đặt lợi ích của khách hàng
                      lên hàng đầu, chúng tôi cam kết cung cấp những sản phẩm và
                      dịch vụ chất lượng cao nhầm thỏa mãn tốt nhất những nhu
                      cầu của khách hàng. Các sản phầm phân phối chính:
                    </p>
                  </div>
                  <ul className="ltn__list-item-1 ltn__list-item-1-before--- clearfix">
                    <li>
                      <i className="fas fa-check-square"></i> Kit Elisa xét
                      nghiệm.
                    </li>
                    <li>
                      <i className="fas fa-check-square"></i> Kit test thử nhanh
                      dùng trong chẩn đoán cận lâm sàng và nghiên cứu.
                    </li>
                    <li>
                      <i className="fas fa-check-square"></i> Sản phẩm độc quyền
                      làm đẹp từ các thành phần tự nhiên.
                    </li>
                  </ul>
                  <div className="about-author-info-2 border-top mt-30 pt-20">
                    <ul>
                      <li>
                        <div className="about-author-info-2-brief  d-flex">
                          <div className="author-img">
                            <img src="assets/img/blog/author.jpg" alt="#" />
                          </div>
                          <div className="author-name-designation">
                            <h4 className="mb-0">Lê Thị Thanh Quang</h4>
                            <small>Giám đốc điều hành</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="about-author-info-2-contact  d-flex">
                          <div className="about-contact-icon d-flex align-self-center mr-10">
                            <i className="icon-call"></i>
                          </div>
                          <div className="about-author-info-2-contact-info">
                            <small>Liên hệ</small>
                            <h6 className="mb-0">028.6292.5568</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ABOUT US AREA END --> */}

        {/* <!-- BANNER AREA START --> */}
        <div className="ltn__banner-area mt-120---">
          <div className="container">
            <div className="row ltn__custom-gutter--- justify-content-center">
              <div className="col-lg-4 col-sm-6">
                <div className="ltn__banner-item">
                  <div className="ltn__banner-img">
                    <a href="#">
                      <img src="assets/img/banner/1.jpg" alt="Banner Image" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="ltn__banner-item">
                  <div className="ltn__banner-img">
                    <a href="#">
                      <img src="assets/img/banner/2.jpg" alt="Banner Image" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="ltn__banner-item">
                  <div className="ltn__banner-img">
                    <a href="#">
                      <img src="assets/img/banner/3.jpg" alt="Banner Image" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- BANNER AREA END --> */}

        {/* <!-- PRODUCT AREA START (product-item-3) --> */}
        <div className="ltn__product-area ltn__product-gutter  no-product-ratting pt-85 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2 text-center">
                  <h1 className="section-title">Sản phẩm nổi bật</h1>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-3">
                <div className="row">
                  <div className="col-lg-12 col-sm-6">
                    <div className="ltn__banner-item">
                      <div className="ltn__banner-img">
                        <a href="#">
                          <img
                            src="assets/img/banner/11.jpg"
                            alt="Banner Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-6">
                    <div className="ltn__banner-item">
                      <div className="ltn__banner-img">
                        <a href="#">
                          <img
                            src="assets/img/banner/12.jpg"
                            alt="Banner Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-12">
                <div className="row ltn__tab-product-slider-one-active--- slick-arrow-1">
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3--- col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/1.png" alt="#" />
                        </a>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge">New</li>
                          </ul>
                        </div>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">RIDASCREEN Leishmania Ab</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$32.00</span>
                          <del>$46.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3--- col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/2.png" alt="#" />
                        </a>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">RIDASCREEN Leishmania Ab</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$25.00</span>
                          <del>$35.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3--- col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/3.png" alt="#" />
                        </a>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge">New</li>
                          </ul>
                        </div>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">RIDASCREEN Leishmania Ab</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$75.00</span>
                          <del>$92.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3--- col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                        <img src="assets/img/product/2.png" alt="#" />
                        </a>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge">New</li>
                          </ul>
                        </div>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">RIDASCREEN Leishmania Ab</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$78.00</span>
                          <del>$85.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3--- col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/1.png" alt="#" />
                        </a>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge">New</li>
                          </ul>
                        </div>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">RIDASCREEN Leishmania Ab</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$150.00</span>
                          <del>$180.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3--- col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/2.png" alt="#" />
                        </a>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge">New</li>
                          </ul>
                        </div>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">RIDASCREEN Leishmania Ab</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$150.00</span>
                          <del>$180.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!--  --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- PRODUCT AREA END --> */}

        {/* <!-- ABOUT US AREA START --> */}
        {/* <div className="ltn__about-us-area section-bg-1 bg-image-left-before pt-115 pb-115">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="about-us-img-wrap about-img-left---"></div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="about-us-info-wrap">
                  <div className="section-title-area ltn__section-title-2--- mb-20">
                    <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                      N95 Facial Covering Mask
                    </h6>
                    <h1 className="section-title">
                      Grade A Safety Masks For Sale. Haurry Up!
                    </h1>
                    <p>
                      Over 39,000 people work for us in more than 70 countries
                      all over the This breadth of global coverage, combined
                      with specialist services
                    </p>
                  </div>
                  <ul className="ltn__list-item-half clearfix">
                    <li>
                      <i className="flaticon-home-2"></i>
                      Activated Carbon
                    </li>
                    <li>
                      <i className="flaticon-mountain"></i>
                      Breathing Valve
                    </li>
                    <li>
                      <i className="flaticon-heart"></i>6 Layer Filteration
                    </li>
                    <li>
                      <i className="flaticon-secure"></i>
                      Rewashes & Reusable
                    </li>
                  </ul>
                  <div className="btn-wrapper animated">
                    <a
                      href="#"
                      className="ltn__secondary-color text-uppercase text-decoration-underline"
                    >
                      View Products
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!-- ABOUT US AREA END --> */}

        {/* <!-- ABOUT US AREA START --> */}
        <div
          className="ltn__about-us-area bg-overlay-white-90--- bg-image pt-115 pb-110 d-none"
          data-bs-bg="assets/img/bg/26.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="about-us-img-wrap about-img-left">
                  {/* <!-- <img src="assets/img/others/7.png" alt="About Us Image"> --> */}
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="about-us-info-wrap">
                  <div className="section-title-area ltn__section-title-2--- mb-20">
                    <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                      N95 Facial Covering Mask
                    </h6>
                    <h1 className="section-title">
                      Grade A Safety Masks For Sale. Haurry Up!
                    </h1>
                    <p>
                      Over 39,000 people work for us in more than 70 countries
                      all over the This breadth of global coverage, combined
                      with specialist services
                    </p>
                  </div>
                  <ul className="ltn__list-item-half clearfix">
                    <li>
                      <i className="flaticon-home-2"></i>
                      Activated Carbon
                    </li>
                    <li>
                      <i className="flaticon-mountain"></i>
                      Breathing Valve
                    </li>
                    <li>
                      <i className="flaticon-heart"></i>6 Layer Filteration
                    </li>
                    <li>
                      <i className="flaticon-secure"></i>
                      Rewashes & Reusable
                    </li>
                  </ul>
                  <div className="btn-wrapper animated">
                    <a
                      href="#"
                      className="ltn__secondary-color text-uppercase text-decoration-underline"
                    >
                      View Products
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ABOUT US AREA END --> */}

        {/* <!-- FEATURE AREA START ( Feature - 3) --> */}
        {/* <div className="ltn__feature-area section-bg-1 mt-90 pt-30 pb-30 mt--65---">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__feature-item-box-wrap ltn__feature-item-box-wrap-2 ltn__border--- section-bg-1">
                  <div className="ltn__feature-item ltn__feature-item-8">
                    <div className="ltn__feature-icon">
                      <img src="assets/img/icons/svg/8-trolley.svg" alt="#" />
                    </div>
                    <div className="ltn__feature-info">
                      <h4>Free shipping</h4>
                      <p>On all orders over $49.00</p>
                    </div>
                  </div>
                  <div className="ltn__feature-item ltn__feature-item-8">
                    <div className="ltn__feature-icon">
                      <img src="assets/img/icons/svg/9-money.svg" alt="#" />
                    </div>
                    <div className="ltn__feature-info">
                      <h4>15 days returns</h4>
                      <p>Moneyback guarantee</p>
                    </div>
                  </div>
                  <div className="ltn__feature-item ltn__feature-item-8">
                    <div className="ltn__feature-icon">
                      <img
                        src="assets/img/icons/svg/10-credit-card.svg"
                        alt="#"
                      />
                    </div>
                    <div className="ltn__feature-info">
                      <h4>Secure checkout</h4>
                      <p>Protected by Paypal</p>
                    </div>
                  </div>
                  <div className="ltn__feature-item ltn__feature-item-8">
                    <div className="ltn__feature-icon">
                      <img
                        src="assets/img/icons/svg/11-gift-card.svg"
                        alt="#"
                      />
                    </div>
                    <div className="ltn__feature-info">
                      <h4>Offer & gift here</h4>
                      <p>On all orders over</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!-- FEATURE AREA END --> */}

        {/* <!-- PRODUCT AREA START (product-item-3) --> */}
        <div className="ltn__product-area ltn__product-gutter pt-85 pb-70 d-none">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2 text-center">
                  <h1 className="section-title">Featured Products</h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ltn__banner-item">
                      <div className="ltn__banner-img">
                        <a href="#">
                          <img
                            src="assets/img/banner/13.jpg"
                            alt="Banner Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="ltn__banner-item">
                      <div className="ltn__banner-img">
                        <a href="#">
                          <img
                            src="assets/img/banner/12.jpg"
                            alt="Banner Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="row ltn__tab-product-slider-one-active--- slick-arrow-1">
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/1.png" alt="#" />
                        </a>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge">New</li>
                          </ul>
                        </div>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">Antiseptic Spray</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$32.00</span>
                          <del>$46.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/2.png" alt="#" />
                        </a>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">Digital Stethoscope</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$25.00</span>
                          <del>$35.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/3.png" alt="#" />
                        </a>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge">New</li>
                          </ul>
                        </div>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">Cosmetic Containers</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$75.00</span>
                          <del>$92.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/4.png" alt="#" />
                        </a>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge">New</li>
                          </ul>
                        </div>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">Cosmetic Containers</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$78.00</span>
                          <del>$85.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/5.png" alt="#" />
                        </a>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge">New</li>
                          </ul>
                        </div>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">Blue Hand Gloves</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$150.00</span>
                          <del>$180.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/6.png" alt="#" />
                        </a>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge">New</li>
                          </ul>
                        </div>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">Thermometer Gun</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$150.00</span>
                          <del>$180.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/7.png" alt="#" />
                        </a>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge">New</li>
                          </ul>
                        </div>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">Cosmetic Containers</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$75.00</span>
                          <del>$92.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- ltn__product-item --> */}
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                    <div className="ltn__product-item ltn__product-item-2 text-left">
                      <div className="product-img">
                        <a href="#">
                          <img src="assets/img/product/8.png" alt="#" />
                        </a>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge">New</li>
                          </ul>
                        </div>
                        {/* <div className="product-hover-action">
                          <ul>
                            <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#quick_view_modal"
                              >
                                <i className="far fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Add to Cart"
                                data-bs-toggle="modal"
                                data-bs-target="#add_to_cart_modal"
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="Wishlist"
                                data-bs-toggle="modal"
                                data-bs-target="#liton_wishlist_modal"
                              >
                                <i className="far fa-heart"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="product-info">
                        <div className="product-ratting">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fas fa-star-half-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="far fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h2 className="product-title">
                          <a href="#">Cosmetic Containers</a>
                        </h2>
                        {/* <div className="product-price">
                          <span>$78.00</span>
                          <del>$85.00</del>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <!--  --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- PRODUCT AREA END --> */}

        {/* <!-- PRODUCT AREA START (product-item-3) --> */}
        <div className="ltn__product-area ltn__product-gutter pt-85 pb-70 d-none">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2 text-center">
                  <h1 className="section-title">Featured Products</h1>
                </div>
              </div>
            </div>
            <div className="row ltn__tab-product-slider-one-active--- slick-arrow-1">
              {/* <!-- ltn__product-item --> */}
              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="ltn__product-item ltn__product-item-3 text-center">
                  <div className="product-img">
                    <a href="#">
                      <img src="assets/img/product/1.png" alt="#" />
                    </a>
                    <div className="product-badge">
                      <ul>
                        <li className="sale-badge">New</li>
                      </ul>
                    </div>
                    {/* <div className="product-hover-action">
                      <ul>
                        <li>
                          <a
                            href="#"
                            title="Quick View"
                            data-bs-toggle="modal"
                            data-bs-target="#quick_view_modal"
                          >
                            <i className="far fa-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Add to Cart"
                            data-bs-toggle="modal"
                            data-bs-target="#add_to_cart_modal"
                          >
                            <i className="fas fa-shopping-cart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Wishlist"
                            data-bs-toggle="modal"
                            data-bs-target="#liton_wishlist_modal"
                          >
                            <i className="far fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="product-info">
                    <div className="product-ratting">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star-half-alt"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="far fa-star"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <h2 className="product-title">
                      <a href="#">Antiseptic Spray</a>
                    </h2>
                    {/* <div className="product-price">
                      <span>$32.00</span>
                      <del>$46.00</del>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <!-- ltn__product-item --> */}
              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="ltn__product-item ltn__product-item-3 text-center">
                  <div className="product-img">
                    <a href="#">
                      <img src="assets/img/product/2.png" alt="#" />
                    </a>
                    {/* <div className="product-hover-action">
                      <ul>
                        <li>
                          <a
                            href="#"
                            title="Quick View"
                            data-bs-toggle="modal"
                            data-bs-target="#quick_view_modal"
                          >
                            <i className="far fa-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Add to Cart"
                            data-bs-toggle="modal"
                            data-bs-target="#add_to_cart_modal"
                          >
                            <i className="fas fa-shopping-cart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Wishlist"
                            data-bs-toggle="modal"
                            data-bs-target="#liton_wishlist_modal"
                          >
                            <i className="far fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="product-info">
                    <div className="product-ratting">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star-half-alt"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="far fa-star"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <h2 className="product-title">
                      <a href="#">Digital Stethoscope</a>
                    </h2>
                    {/* <div className="product-price">
                      <span>$25.00</span>
                      <del>$35.00</del>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <!-- ltn__product-item --> */}
              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="ltn__product-item ltn__product-item-3 text-center">
                  <div className="product-img">
                    <a href="#">
                      <img src="assets/img/product/3.png" alt="#" />
                    </a>
                    <div className="product-badge">
                      <ul>
                        <li className="sale-badge">New</li>
                      </ul>
                    </div>
                    {/* <div className="product-hover-action">
                      <ul>
                        <li>
                          <a
                            href="#"
                            title="Quick View"
                            data-bs-toggle="modal"
                            data-bs-target="#quick_view_modal"
                          >
                            <i className="far fa-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Add to Cart"
                            data-bs-toggle="modal"
                            data-bs-target="#add_to_cart_modal"
                          >
                            <i className="fas fa-shopping-cart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Wishlist"
                            data-bs-toggle="modal"
                            data-bs-target="#liton_wishlist_modal"
                          >
                            <i className="far fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="product-info">
                    <div className="product-ratting">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star-half-alt"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="far fa-star"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <h2 className="product-title">
                      <a href="#">Cosmetic Containers</a>
                    </h2>
                    {/* <div className="product-price">
                      <span>$75.00</span>
                      <del>$92.00</del>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <!-- ltn__product-item --> */}
              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="ltn__product-item ltn__product-item-3 text-center">
                  <div className="product-img">
                    <a href="#">
                      <img src="assets/img/product/4.png" alt="#" />
                    </a>
                    <div className="product-badge">
                      <ul>
                        <li className="sale-badge">New</li>
                      </ul>
                    </div>
                    {/* <div className="product-hover-action">
                      <ul>
                        <li>
                          <a
                            href="#"
                            title="Quick View"
                            data-bs-toggle="modal"
                            data-bs-target="#quick_view_modal"
                          >
                            <i className="far fa-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Add to Cart"
                            data-bs-toggle="modal"
                            data-bs-target="#add_to_cart_modal"
                          >
                            <i className="fas fa-shopping-cart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Wishlist"
                            data-bs-toggle="modal"
                            data-bs-target="#liton_wishlist_modal"
                          >
                            <i className="far fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="product-info">
                    <div className="product-ratting">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star-half-alt"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="far fa-star"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <h2 className="product-title">
                      <a href="#">Cosmetic Containers</a>
                    </h2>
                    {/* <div className="product-price">
                      <span>$78.00</span>
                      <del>$85.00</del>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <!-- ltn__product-item --> */}
              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="ltn__product-item ltn__product-item-3 text-center">
                  <div className="product-img">
                    <a href="#">
                      <img src="assets/img/product/5.png" alt="#" />
                    </a>
                    <div className="product-badge">
                      <ul>
                        <li className="sale-badge">New</li>
                      </ul>
                    </div>
                    {/* <div className="product-hover-action">
                      <ul>
                        <li>
                          <a
                            href="#"
                            title="Quick View"
                            data-bs-toggle="modal"
                            data-bs-target="#quick_view_modal"
                          >
                            <i className="far fa-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Add to Cart"
                            data-bs-toggle="modal"
                            data-bs-target="#add_to_cart_modal"
                          >
                            <i className="fas fa-shopping-cart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Wishlist"
                            data-bs-toggle="modal"
                            data-bs-target="#liton_wishlist_modal"
                          >
                            <i className="far fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="product-info">
                    <div className="product-ratting">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star-half-alt"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="far fa-star"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <h2 className="product-title">
                      <a href="#">Blue Hand Gloves</a>
                    </h2>
                    {/* <div className="product-price">
                      <span>$150.00</span>
                      <del>$180.00</del>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <!-- ltn__product-item --> */}
              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="ltn__product-item ltn__product-item-3 text-center">
                  <div className="product-img">
                    <a href="#">
                      <img src="assets/img/product/6.png" alt="#" />
                    </a>
                    <div className="product-badge">
                      <ul>
                        <li className="sale-badge">New</li>
                      </ul>
                    </div>
                    {/* <div className="product-hover-action">
                      <ul>
                        <li>
                          <a
                            href="#"
                            title="Quick View"
                            data-bs-toggle="modal"
                            data-bs-target="#quick_view_modal"
                          >
                            <i className="far fa-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Add to Cart"
                            data-bs-toggle="modal"
                            data-bs-target="#add_to_cart_modal"
                          >
                            <i className="fas fa-shopping-cart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Wishlist"
                            data-bs-toggle="modal"
                            data-bs-target="#liton_wishlist_modal"
                          >
                            <i className="far fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="product-info">
                    <div className="product-ratting">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star-half-alt"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="far fa-star"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <h2 className="product-title">
                      <a href="#">Thermometer Gun</a>
                    </h2>
                    {/* <div className="product-price">
                      <span>$150.00</span>
                      <del>$180.00</del>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <!-- ltn__product-item --> */}
              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="ltn__product-item ltn__product-item-3 text-center">
                  <div className="product-img">
                    <a href="#">
                      <img src="assets/img/product/7.png" alt="#" />
                    </a>
                    <div className="product-badge">
                      <ul>
                        <li className="sale-badge">New</li>
                      </ul>
                    </div>
                    {/* <div className="product-hover-action">
                      <ul>
                        <li>
                          <a
                            href="#"
                            title="Quick View"
                            data-bs-toggle="modal"
                            data-bs-target="#quick_view_modal"
                          >
                            <i className="far fa-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Add to Cart"
                            data-bs-toggle="modal"
                            data-bs-target="#add_to_cart_modal"
                          >
                            <i className="fas fa-shopping-cart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Wishlist"
                            data-bs-toggle="modal"
                            data-bs-target="#liton_wishlist_modal"
                          >
                            <i className="far fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="product-info">
                    <div className="product-ratting">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star-half-alt"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="far fa-star"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <h2 className="product-title">
                      <a href="#">Cosmetic Containers</a>
                    </h2>
                    {/* <div className="product-price">
                      <span>$75.00</span>
                      <del>$92.00</del>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <!-- ltn__product-item --> */}
              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="ltn__product-item ltn__product-item-3 text-center">
                  <div className="product-img">
                    <a href="#">
                      <img src="assets/img/product/8.png" alt="#" />
                    </a>
                    <div className="product-badge">
                      <ul>
                        <li className="sale-badge">New</li>
                      </ul>
                    </div>
                    {/* <div className="product-hover-action">
                      <ul>
                        <li>
                          <a
                            href="#"
                            title="Quick View"
                            data-bs-toggle="modal"
                            data-bs-target="#quick_view_modal"
                          >
                            <i className="far fa-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Add to Cart"
                            data-bs-toggle="modal"
                            data-bs-target="#add_to_cart_modal"
                          >
                            <i className="fas fa-shopping-cart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Wishlist"
                            data-bs-toggle="modal"
                            data-bs-target="#liton_wishlist_modal"
                          >
                            <i className="far fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="product-info">
                    <div className="product-ratting">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star-half-alt"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="far fa-star"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <h2 className="product-title">
                      <a href="#">Cosmetic Containers</a>
                    </h2>
                    {/* <div className="product-price">
                      <span>$78.00</span>
                      <del>$85.00</del>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <!--  --> */}
            </div>
          </div>
        </div>
        {/* <!-- PRODUCT AREA END --> */}

        {/* <!-- CALL TO ACTION START (call-to-action-6) --> */}
        <div
          className="ltn__call-to-action-area call-to-action-6 before-bg-bottom d-none"
          data-bs-bg="assets/img/1.jpg--"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
                  <div className="coll-to-info text-color-white">
                    <h1>
                      Buy medical disposable face mask <br /> to protect your
                      loved ones
                    </h1>
                  </div>
                  <div className="btn-wrapper">
                    <a className="btn btn-effect-3 btn-white" href="#">
                      Explore Products <i className="icon-next"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- CALL TO ACTION END --> */}

        {/* <!-- FOOTER AREA START --> */}
        <footer class="ltn__footer-area  ">
          <div class="footer-top-area  section-bg-2 plr--5">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-4 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-about-widget">
                    {/* <div class="footer-logo">
                      <div class="site-logo">
                        <img src="assets/img/logo.png" alt="Logo" />
                      </div>
                    </div> */}
                    <h4 class="footer-title">VỀ HNM</h4>

                    <p>
                      HoangNhatMinh luôn tự hào là nhà tiên phong trong lĩnh vực
                      thuốc với nhiều sáng tạo và sản phẩm đã được công nhận bởi
                      bộ y tế về an toàn và hiệu quả đem đến cho người sử dụng.
                    </p>

                    <div class="ltn__social-media mt-20">
                      <ul>
                        <li>
                          <a href="#" title="Facebook">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Twitter">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Linkedin">
                            <i class="fab fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Youtube">
                            <i class="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-menu-widget clearfix">
                    <h4 class="footer-title">HỖ TRỢ</h4>
                    <div class="footer-menu">
                      <ul>
                        <li>
                          <a>FAQ</a>
                        </li>
                        <li>
                          <a>Chính sách bảo mật</a>
                        </li>
                        <li>
                          <a>Chính sách thanh toán</a>
                        </li>
                        <li>
                          <a>Chính sách giao hàng</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-menu-widget clearfix">
                    <h4 class="footer-title">SHOP</h4>
                    <div class="footer-menu">
                      <ul>
                        <li>
                          <a href="/shop">ELISA</a>
                        </li>
                        <li>
                          <a href="/shop">PCR</a>
                        </li>
                        <li>
                          <a href="/shop">Test thử nhanh</a>
                        </li>
                        <li>
                          <a href="/shop">Làm đẹp</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-newsletter-widget">
                    <h4 class="footer-title">LIÊN HỆ</h4>
                    <div class="footer-address">
                      <ul>
                        <li>
                          <div class="footer-address-icon">
                            <i class="icon-placeholder"></i>
                          </div>
                          <div class="footer-address-info">
                            <p>72 Đường C18, Tân Bình, Tp. Hồ Chí Minh</p>
                          </div>
                        </li>
                        <li>
                          <div class="footer-address-icon">
                            <i class="icon-call"></i>
                          </div>
                          <div class="footer-address-info">
                            <p>
                              <a href="tel:+02862925568">(028).6292.5568</a>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div class="footer-address-icon">
                            <i class="icon-mail"></i>
                          </div>
                          <div class="footer-address-info">
                            <p>
                              <a href="mailto:example@example.com">
                                hoangnhatminh@lab.com
                              </a>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    {/* <img
                      class="mt-30"
                      src="assets/img/logo-bct.png"
                      alt="Payment Image"
                      width={150}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
            <div class="container-fluid ltn__border-top-2">
              <div class="row">
                <div class="col-md-12 col-12 text-center">
                  <div class="ltn__copyright-design clearfix">
                    <p>
                      All Rights Reserved @ Hoang Nhat Minh{" "}
                      <span class="current-year"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* <!-- FOOTER AREA END --> */}

        {/* <!-- MODAL AREA START (Quick View Modal) --> */}
        <div className="ltn__modal-area ltn__quick-view-modal-area">
          <div className="modal fade" id="quick_view_modal" tabindex="-1">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    {/* <!-- <i className="fas fa-times"></i> --> */}
                  </button>
                </div>
                <div className="modal-body">
                  <div className="ltn__quick-view-modal-inner">
                    <div className="modal-product-item">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <div className="modal-product-img">
                            <img src="assets/img/product/4.png" alt="#" />
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="modal-product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                                <li className="review-total">
                                  {" "}
                                  <a href="#"> ( 95 Reviews )</a>
                                </li>
                              </ul>
                            </div>
                            <h3>
                              <a href="#">Digital Stethoscope</a>
                            </h3>
                            {/* <div className="product-price">
                              <span>$15.00</span>
                              <del>$25.00</del>
                            </div> */}
                            <hr />
                            <div className="modal-product-brief">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Dignissimos repellendus
                                repudiandae incidunt quidem pariatur expedita,
                                quo quis modi tempore non.
                              </p>
                            </div>
                            <div className="modal-product-meta ltn__product-details-menu-1 d-none">
                              <ul>
                                <li>
                                  <strong>Categories:</strong>
                                  <span>
                                    <a href="#">Parts</a>
                                    <a href="#">Car</a>
                                    <a href="#">Seat</a>
                                    <a href="#">Cover</a>
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="ltn__product-details-menu-2 d-none">
                              <ul>
                                <li>
                                  <div className="cart-plus-minus">
                                    <input
                                      type="text"
                                      value="02"
                                      name="qtybutton"
                                      className="cart-plus-minus-box"
                                    />
                                  </div>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="theme-btn-1 btn btn-effect-1"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                    <span>ADD TO CART</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {/* <!-- <hr> --> */}
                            <div className="ltn__product-details-menu-3">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    className=""
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                    <span>Add to Wishlist</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className=""
                                    title="Compare"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="fas fa-exchange-alt"></i>
                                    <span>Compare</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <hr />
                            <div className="ltn__social-media">
                              <ul>
                                <li>Share:</li>
                                <li>
                                  <a href="#" title="Facebook">
                                    <i className="fab fa-facebook-f"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" title="Twitter">
                                    <i className="fab fa-twitter"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" title="Linkedin">
                                    <i className="fab fa-linkedin"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" title="Instagram">
                                    <i className="fab fa-instagram"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <label className="float-end mb-0">
                              <a className="text-decoration" href="#">
                                <small>View Details</small>
                              </a>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- MODAL AREA END --> */}

        {/* <!-- MODAL AREA START (Add To Cart Modal) --> */}
        <div className="ltn__modal-area ltn__add-to-cart-modal-area">
          <div className="modal fade" id="add_to_cart_modal" tabindex="-1">
            <div className="modal-dialog modal-md" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="ltn__quick-view-modal-inner">
                    <div className="modal-product-item">
                      <div className="row">
                        <div className="col-12">
                          <div className="modal-product-img">
                            <img src="assets/img/product/1.png" alt="#" />
                          </div>
                          <div className="modal-product-info">
                            <h5>
                              <a href="#">Digital Stethoscope</a>
                            </h5>
                            <p className="added-cart">
                              <i className="fa fa-check-circle"></i>{" "}
                              Successfully added to your Cart
                            </p>
                            <div className="btn-wrapper">
                              <a
                                href="#"
                                className="theme-btn-1 btn btn-effect-1"
                              >
                                View Cart
                              </a>
                              <a
                                href="#"
                                className="theme-btn-2 btn btn-effect-2"
                              >
                                Checkout
                              </a>
                            </div>
                          </div>
                          <div className="additional-info d-none">
                            <p>
                              We want to give you <b>10% discount</b> for your
                              first order, <br /> Use discount code at checkout
                            </p>
                            <div className="payment-method">
                              <img src="assets/img/icons/payment.png" alt="#" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__modal-area ltn__add-to-cart-modal-area">
          <div className="modal fade" id="liton_wishlist_modal" tabindex="-1">
            <div className="modal-dialog modal-md" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="ltn__quick-view-modal-inner">
                    <div className="modal-product-item">
                      <div className="row">
                        <div className="col-12">
                          <div className="modal-product-img">
                            <img src="assets/img/product/7.png" alt="#" />
                          </div>
                          <div className="modal-product-info">
                            <h5>
                              <a href="#">Digital Stethoscope</a>
                            </h5>
                            <p className="added-cart">
                              <i className="fa fa-check-circle"></i>{" "}
                              Successfully added to your Wishlist
                            </p>
                            <div className="btn-wrapper">
                              <a
                                href="#"
                                className="theme-btn-1 btn btn-effect-1"
                              >
                                View Wishlist
                              </a>
                            </div>
                          </div>
                          <div className="additional-info d-none">
                            <p>
                              We want to give you <b>10% discount</b> for your
                              first order, <br /> Use discount code at checkout
                            </p>
                            <div className="payment-method">
                              <img src="assets/img/icons/payment.png" alt="#" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- preloader area start --> */}
      <div className="preloader d-none" id="preloader">
        <div className="preloader-inner">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
      {/* <!-- preloader area end --> */}

      {/* <!-- All JS Plugins --> */}
      <script src="assets/js/plugins.js"></script>
      {/* <!-- Main JS --> */}
      <script src="assets/js/main.js"></script>
    </React.Fragment>
  );
};

export default Home;
