import React from "react";

const Product = ({ product }) => {
  const baseImageURL = "https://api.hoangnhatminh.vn";
  return (
    <div className="col-xl-4 col-sm-6 col-6">
      <div
        className="ltn__product-item ltn__product-item-3 text-center"
        style={{
          border: "1px solid #ddd",
          marginBottom: "20px",
          boxSizing: "border-box",
          textAlign: "center",
        }}
      >
        <div className="product-img shop">
          <a href={`/product/${product.slug}`}>
            <img
              className="img-fluid"
              style={{ width: "100%", objectFit: "cover" }}
              src={`${baseImageURL}/images/${product.imagePath[0]}`}
              alt={product.name}
            />
          </a>
        </div>
        <div
          className="product-info"
          style={{
            height: "120px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <h2 className="product-title">
            <a href={`/product/${product.slug}`}>{product.name}</a>
          </h2>
          <div className="product-price">
            <span>
              {product.price
                ? product.price.toLocaleString("it-IT", {
                    style: "currency",
                    currency: "VND",
                  })
                : "Liên hệ"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
