import React from "react";

const AboutUs = () => {
  return (
    <React.Fragment>
      <div class="body-wrapper">
        {/* HEADER AREA START (header-3) */}
        <header class="ltn__header-area ltn__header-3 sticky-top bg-white shadow">
          {/* <!-- ltn__header-top-area end --> 
    <!-- ltn__header-middle-area start --> */}
          <div class="ltn__header-middle-area">
            <div class="container">
              <div class="row">
                <div class="col d-flex">
                  <div class="col site-logo">
                    <a href="/">
                      <img src="assets/img/logo.png" alt="Logo" />
                    </a>
                  </div>
                  <div class="col header-feature-item">
                    <div class="header-feature-icon">
                      <i class="icon-call"></i>
                    </div>
                    <div class="header-feature-info">
                      <h6>Phone</h6>
                      <p>
                        <a href="#">028.6292.5568</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col header-contact-serarch-column d-none d-lg-block">
                  <div class="header-contact-search">
                    <div className="col header-menu-column menu-color-white---">
                      <div className="header-menu d-none d-xl-block">
                        <nav>
                          <div className="ltn__main-menu">
                            <ul>
                              <li>
                                <a href="/">Trang chủ</a>
                              </li>
                              <li>
                                <a href="/shop">Sản phẩm</a>
                              </li>
                              <li>
                                <a href="/aboutUs">Liên hệ</a>
                              </li>
                            </ul>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- header-bottom-area end --> */}
        </header>
        {/* HEADER AREA END */}

        {/* MOBILE MENU START */}
        <div class="mobile-header-menu-fullwidth mb-30 d-block d-lg-none">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                {/* Mobile Menu Button */}
                <div class="mobile-menu-toggle d-lg-none">
                  <span>MENU</span>
                  <a
                    href="#ltn__utilize-mobile-menu"
                    class="ltn__utilize-toggle"
                  >
                    <svg viewBox="0 0 800 600">
                      <path
                        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                        id="top"
                      ></path>
                      <path d="M300,320 L540,320" id="middle"></path>
                      <path
                        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                        id="bottom"
                        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MOBILE MENU END */}

        {/* Utilize Cart Menu Start */}
        <div
          id="ltn__utilize-cart-menu"
          class="ltn__utilize ltn__utilize-cart-menu"
        >
          <div class="ltn__utilize-menu-inner ltn__scrollbar">
            <div class="ltn__utilize-menu-head">
              <span class="ltn__utilize-menu-title">Cart</span>
              <button class="ltn__utilize-close">×</button>
            </div>
            <div class="mini-cart-product-area ltn__scrollbar">
              <div class="mini-cart-item clearfix">
                <div class="mini-cart-img">
                  <a href="#">
                    <img src="assets/imgproduct/1.png" alt="Image" />
                  </a>
                  <span class="mini-cart-item-delete">
                    <i class="icon-cancel"></i>
                  </span>
                </div>
                <div class="mini-cart-info">
                  <h6>
                    <a href="#">Antiseptic Spray</a>
                  </h6>
                  <span class="mini-cart-quantity">1 x $65.00</span>
                </div>
              </div>
              <div class="mini-cart-item clearfix">
                <div class="mini-cart-img">
                  <a href="#">
                    <img src="assets/imgproduct/2.png" alt="Image" />
                  </a>
                  <span class="mini-cart-item-delete">
                    <i class="icon-cancel"></i>
                  </span>
                </div>
                <div class="mini-cart-info">
                  <h6>
                    <a href="#">Digital Stethoscope</a>
                  </h6>
                  <span class="mini-cart-quantity">1 x $85.00</span>
                </div>
              </div>
              <div class="mini-cart-item clearfix">
                <div class="mini-cart-img">
                  <a href="#">
                    <img src="assets/imgproduct/3.png" alt="Image" />
                  </a>
                  <span class="mini-cart-item-delete">
                    <i class="icon-cancel"></i>
                  </span>
                </div>
                <div class="mini-cart-info">
                  <h6>
                    <a href="#">Cosmetic Containers</a>
                  </h6>
                  <span class="mini-cart-quantity">1 x $92.00</span>
                </div>
              </div>
              <div class="mini-cart-item clearfix">
                <div class="mini-cart-img">
                  <a href="#">
                    <img src="assets/imgproduct/4.png" alt="Image" />
                  </a>
                  <span class="mini-cart-item-delete">
                    <i class="icon-cancel"></i>
                  </span>
                </div>
                <div class="mini-cart-info">
                  <h6>
                    <a href="#">Thermometer Gun</a>
                  </h6>
                  <span class="mini-cart-quantity">1 x $68.00</span>
                </div>
              </div>
            </div>
            <div class="mini-cart-footer">
              <div class="mini-cart-sub-total">
                <h5>
                  Subtotal: <span>$310.00</span>
                </h5>
              </div>
              <div class="btn-wrapper">
                <a href="cart.html" class="theme-btn-1 btn btn-effect-1">
                  View Cart
                </a>
                <a href="cart.html" class="theme-btn-2 btn btn-effect-2">
                  Checkout
                </a>
              </div>
              <p>Free Shipping on All Orders Over $100!</p>
            </div>
          </div>
        </div>
        {/* Utilize Cart Menu End */}

        {/* Utilize Mobile Menu Start */}
        <div
          id="ltn__utilize-mobile-menu"
          class="ltn__utilize ltn__utilize-mobile-menu"
        >
          <div class="ltn__utilize-menu-inner ltn__scrollbar">
            <div class="ltn__utilize-menu-head">
              <div class="site-logo">
                <a href="index.html">
                  <img src="assets/imglogo.png" alt="Logo" />
                </a>
              </div>
              <button class="ltn__utilize-close">×</button>
            </div>
            <div class="ltn__utilize-menu-search-form">
              <form action="#">
                <input type="text" placeholder="Search..." />
                <button>
                  <i class="fas fa-search"></i>
                </button>
              </form>
            </div>
            <div class="ltn__utilize-menu">
              <ul>
                <li>
                  <a href="#">Home</a>
                  <ul class="sub-menu">
                    <li>
                      <a href="index.html">Home Style 01</a>
                    </li>
                    <li>
                      <a href="index-2.html">Home Style 02</a>
                    </li>
                    <li>
                      <a href="index-3.html">Home Style 03</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">About</a>
                  <ul class="sub-menu">
                    <li>
                      <a href="about.html">About</a>
                    </li>
                    <li>
                      <a href="service.html">Services</a>
                    </li>
                    <li>
                      <a href="service-details.html">Service Details</a>
                    </li>
                    <li>
                      <a href="portfolio.html">Portfolio</a>
                    </li>
                    <li>
                      <a href="portfolio-2.html">Portfolio - 02</a>
                    </li>
                    <li>
                      <a href="portfolio-details.html">Portfolio Details</a>
                    </li>
                    <li>
                      <a href="team.html">Team</a>
                    </li>
                    <li>
                      <a href="team-details.html">Team Details</a>
                    </li>
                    <li>
                      <a href="faq.html">FAQ</a>
                    </li>
                    <li>
                      <a href="locations.html">Google Map Locations</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Shop</a>
                  <ul class="sub-menu">
                    <li>
                      <a href="shop.html">Shop</a>
                    </li>
                    <li>
                      <a href="shop-grid.html">Shop Grid</a>
                    </li>
                    <li>
                      <a href="shop-left-sidebar.html">Shop Left sidebar</a>
                    </li>
                    <li>
                      <a href="shop-right-sidebar.html">Shop right sidebar</a>
                    </li>
                    <li>
                      <a href="product-details.html">Shop details </a>
                    </li>
                    <li>
                      <a href="cart.html">Cart</a>
                    </li>
                    <li>
                      <a href="wishlist.html">Wishlist</a>
                    </li>
                    <li>
                      <a href="checkout.html">Checkout</a>
                    </li>
                    <li>
                      <a href="order-tracking.html">Order Tracking</a>
                    </li>
                    <li>
                      <a href="account.html">My Account</a>
                    </li>
                    <li>
                      <a href="login.html">Sign in</a>
                    </li>
                    <li>
                      <a href="register.html">Register</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">News</a>
                  <ul class="sub-menu">
                    <li>
                      <a href="blog.html">News</a>
                    </li>
                    <li>
                      <a href="blog-grid.html">News Grid</a>
                    </li>
                    <li>
                      <a href="blog-left-sidebar.html">News Left sidebar</a>
                    </li>
                    <li>
                      <a href="blog-right-sidebar.html">News Right sidebar</a>
                    </li>
                    <li>
                      <a href="blog-details.html">News details</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Pages</a>
                  <ul class="sub-menu">
                    <li>
                      <a href="about.html">About</a>
                    </li>
                    <li>
                      <a href="service.html">Services</a>
                    </li>
                    <li>
                      <a href="service-details.html">Service Details</a>
                    </li>
                    <li>
                      <a href="portfolio.html">Portfolio</a>
                    </li>
                    <li>
                      <a href="portfolio-2.html">Portfolio - 02</a>
                    </li>
                    <li>
                      <a href="portfolio-details.html">Portfolio Details</a>
                    </li>
                    <li>
                      <a href="team.html">Team</a>
                    </li>
                    <li>
                      <a href="team-details.html">Team Details</a>
                    </li>
                    <li>
                      <a href="faq.html">FAQ</a>
                    </li>
                    <li>
                      <a href="history.html">History</a>
                    </li>
                    <li>
                      <a href="add-listing.html">Add Listing</a>
                    </li>
                    <li>
                      <a href="locations.html">Google Map Locations</a>
                    </li>
                    <li>
                      <a href="404.html">404</a>
                    </li>
                    <li>
                      <a href="contact.html">Contact</a>
                    </li>
                    <li>
                      <a href="coming-soon.html">Coming Soon</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="contact.html">Contact</a>
                </li>
              </ul>
            </div>
            <div class="ltn__utilize-buttons ltn__utilize-buttons-2">
              <ul>
                <li>
                  <a href="account.html" title="My Account">
                    <span class="utilize-btn-icon">
                      <i class="far fa-user"></i>
                    </span>
                    My Account
                  </a>
                </li>
                <li>
                  <a href="wishlist.html" title="Wishlist">
                    <span class="utilize-btn-icon">
                      <i class="far fa-heart"></i>
                      <sup>3</sup>
                    </span>
                    Wishlist
                  </a>
                </li>
                <li>
                  <a href="cart.html" title="Shoping Cart">
                    <span class="utilize-btn-icon">
                      <i class="fas fa-shopping-cart"></i>
                      <sup>5</sup>
                    </span>
                    Shoping Cart
                  </a>
                </li>
              </ul>
            </div>
            <div class="ltn__social-media-2">
              <ul>
                <li>
                  <a href="#" title="Facebook">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#" title="Twitter">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#" title="Linkedin">
                    <i class="fab fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href="#" title="Instagram">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Utilize Mobile Menu End */}

        <div class="ltn__utilize-overlay"></div>

        {/* BREADCRUMB AREA START */}
        <div
          class="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "
          data-bs-bg="img/bg/14.jpg"
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="ltn__breadcrumb-inner">
                  <h1 class="page-title">Liên hệ</h1>
                  <div class="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <a href="/">
                          <span class="ltn__secondary-color">
                            <i class="fas fa-home"></i>
                          </span>{" "}
                          Home
                        </a>
                      </li>
                      <li>Contact</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}

        {/* CONTACT ADDRESS AREA START */}
        <div class="ltn__contact-address-area mb-90">
          <div class="container">
            <div class="row">
              <div class="col-lg-4">
                <div class="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div class="ltn__contact-address-icon">
                    <img src="assets/img/icons/10.png" alt="Icon Image" />
                  </div>
                  <h3>Email</h3>
                  <p>
                    hoangnhatminh@mail.com <br />
                    jobs@webexample.com
                  </p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div class="ltn__contact-address-icon">
                    <img src="assets/img/icons/11.png" alt="Icon Image" />
                  </div>
                  <h3>Số điện thoại</h3>
                  <p>
                    028.6292.5568 <br /> HOTLINE
                  </p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div class="ltn__contact-address-icon">
                    <img src="assets/img/icons/12.png" alt="Icon Image" />
                  </div>
                  <h3>Địa chỉ</h3>
                  <p>
                    72 Đ.C18, Phường 12, Tân Bình,
                    <br /> Thành phố Hồ Chí Minh, Vietnam
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* CONTACT ADDRESS AREA END */}

        {/* CONTACT MESSAGE AREA START */}
        {/* <div class="ltn__contact-message-area mb-120 mb--100">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="ltn__form-box contact-form-box box-shadow white-bg">
                  <h4 class="title-2">Get A Quote</h4>
                  <form id="contact-form" action="mail.php" method="post">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="input-item input-item-name ltn__custom-icon">
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-item input-item-email ltn__custom-icon">
                          <input
                            type="email"
                            name="email"
                            placeholder="Enter email address"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-item input-item-subject ltn__custom-icon">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Enter the subject"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-item input-item-phone ltn__custom-icon">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Enter phone number"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="input-item input-item-textarea ltn__custom-icon">
                      <textarea
                        name="message"
                        placeholder="Enter message"
                      ></textarea>
                    </div>
                    <p>
                      <label class="input-info-save mb-0">
                        <input type="checkbox" name="agree" /> Save my name,
                        email, and website in this browser for the next time I
                        comment.
                      </label>
                    </p>
                    <div class="btn-wrapper mt-0">
                      <button
                        class="btn theme-btn-1 btn-effect-1 text-uppercase"
                        type="submit"
                      >
                        get a free service
                      </button>
                    </div>
                    <p class="form-messege mb-0 mt-20"></p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* CONTACT MESSAGE AREA END */}

        {/* GOOGLE MAP AREA START */}
        <div class="google-map mb-120">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.175416237346!2d106.6480813105853!3d10.797873289307791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529498e422719%3A0x1c808a652c132321!2zNzIgxJAuQzE4LCBQaMaw4budbmcgMTIsIFTDom4gQsOsbmgsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCwgVmlldG5hbQ!5e0!3m2!1sen!2s!4v1695360480603!5m2!1sen!2s"
            width="100%"
            height="100%"
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
        {/* GOOGLE MAP AREA END */}

        {/* FOOTER AREA START */}
        <footer class="ltn__footer-area  ">
          <div class="footer-top-area  section-bg-2 plr--5">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-4 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-about-widget">
                    {/* <div class="footer-logo">
                      <div class="site-logo">
                        <img src="assets/img/logo.png" alt="Logo" />
                      </div>
                    </div> */}
                    <h4 class="footer-title">VỀ HNM</h4>

                    <p>
                      HoangNhatMinh luôn tự hào là nhà tiên phong trong lĩnh vực
                      thuốc với nhiều sáng tạo và sản phẩm đã được công nhận bởi
                      bộ y tế về an toàn và hiệu quả đem đến cho người sử dụng.
                    </p>

                    <div class="ltn__social-media mt-20">
                      <ul>
                        <li>
                          <a href="#" title="Facebook">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Twitter">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Linkedin">
                            <i class="fab fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Youtube">
                            <i class="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-menu-widget clearfix">
                    <h4 class="footer-title">HỖ TRỢ</h4>
                    <div class="footer-menu">
                      <ul>
                        <li>
                          <a>FAQ</a>
                        </li>
                        <li>
                          <a>Chính sách bảo mật</a>
                        </li>
                        <li>
                          <a>Chính sách thanh toán</a>
                        </li>
                        <li>
                          <a>Chính sách giao hàng</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-menu-widget clearfix">
                    <h4 class="footer-title">SHOP</h4>
                    <div class="footer-menu">
                      <ul>
                        <li>
                          <a href="/shop">ELISA</a>
                        </li>
                        <li>
                          <a href="/shop">PCR</a>
                        </li>
                        <li>
                          <a href="/shop">Test thử nhanh</a>
                        </li>
                        <li>
                          <a href="/shop">Làm đẹp</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 col-sm-6 col-12">
                  <div class="footer-widget footer-newsletter-widget">
                    <h4 class="footer-title">LIÊN HỆ</h4>
                    <div class="footer-address">
                      <ul>
                        <li>
                          <div class="footer-address-icon">
                            <i class="icon-placeholder"></i>
                          </div>
                          <div class="footer-address-info">
                            <p>72 Đường C18, Tân Bình, Tp. Hồ Chí Minh</p>
                          </div>
                        </li>
                        <li>
                          <div class="footer-address-icon">
                            <i class="icon-call"></i>
                          </div>
                          <div class="footer-address-info">
                            <p>
                              <a href="tel:+02862925568">(028).6292.5568</a>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div class="footer-address-icon">
                            <i class="icon-mail"></i>
                          </div>
                          <div class="footer-address-info">
                            <p>
                              <a href="mailto:example@example.com">
                                hoangnhatminh@lab.com
                              </a>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    {/* <img
                      class="mt-30"
                      src="assets/img/logo-bct.png"
                      alt="Payment Image"
                      width={150}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
            <div class="container-fluid ltn__border-top-2">
              <div class="row">
                <div class="col-md-12 col-12 text-center">
                  <div class="ltn__copyright-design clearfix">
                    <p>
                      All Rights Reserved @ Hoang Nhat Minh{" "}
                      <span class="current-year"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* FOOTER AREA END */}
      </div>

      <script src="js/plugins.js"></script>
      <script src="js/contact.js"></script>
      <script src="js/main.js"></script>
    </React.Fragment>
  );
};

export default AboutUs;
